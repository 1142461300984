<template>
    <div class="mainContentPaper" >
	<el-form :model="ruleFormPaper" :rules="rulesPaper" ref="ruleFormPaper" label-width="100px" class="demo-ruleForm">
		<el-form-item label="标题" prop="title">
			<el-input v-model="ruleFormPaper.title"></el-input>
		</el-form-item>
		<div class="antistop">
			<el-form-item label="关键词" prop="antistop">
				<el-input v-model="ruleFormPaper.antistop"></el-input>
				<el-input v-model="ruleFormPaper.antistop1"></el-input>
				<el-input v-model="ruleFormPaper.antistop2"></el-input>
				<el-input v-model="ruleFormPaper.antistop3"></el-input>
			</el-form-item>
		</div>
		<el-form-item label="基金项目">
			<el-input v-model="ruleFormPaper.project"></el-input>
		</el-form-item>
		<el-form-item label="第一作者">
			<el-input v-model="ruleFormPaper.oneauthor"></el-input>
		</el-form-item>
		<el-form-item label="其他作者">
			<el-input v-model="ruleFormPaper.otherauthor"></el-input>
		</el-form-item>
		<div class="authorAdd">
			<p><span>+</span>（如有多个作者，请点击“+”填写。）</p>
		</div>
		<el-form-item label="通讯作者">
			<el-input v-model="ruleFormPaper.phoneauthor"></el-input>
		</el-form-item>
		<el-form-item label="摘要">
			<el-input type="textarea" v-model="ruleFormPaper.abstract"></el-input>
		</el-form-item>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="upload">上传</span>
		</div>
		<p class="remarkText">支持WORD，且文件不超过15M；</p>
	</el-form>
	<div class="btnPaper">
		<span class="btnPaperCancel" @click="btnCancel('ruleFormPaper')">取消</span>
		<span class="btnPaperUpload">上传</span>
	</div>
</div>
</template>
<script>
export default {
	data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			/*论文*/
			ruleFormPaper:{
				title:'',
				oneauthor:'',/*第一作者*/
				otherauthor:'',/*其他作者*/
				phoneauthor:'',/*通讯作者*/
				abstract:'',/*摘要*/
				project:'',/*基金项目*/
				antistop:'',/*关键字*/
				antistop1:'',/*关键字1*/
				antistop2:'',/*关键字2*/
				antistop3:'',/*关键字3*/
			},
			rulesPaper:{
				title:{ required: true, message: '请输入标题', trigger: 'blur' },
				antistop:{ required: true, message: '请输入关键词', trigger: 'blur' },
			},
		}
	},
	methods:{
		btnCancel:function(formName){
			this.$refs[formName].resetFields();
		},
	}
}
</script>