import Vue from '../main.js'

import './index.css'

var vm = new Vue({
  el: '#home',
  data: () => {
    return {
      isDev:false,
      percent: 0,
      imgFlag: false,
      fileName: '',
      isLt100M: '',
      isLt15M: '',
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        freeMode: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-prev',
          prevEl: '.swiper-button-next',
        }
      },
      swiperExpert: {
        slidesPerView: 2,
        spaceBetween: 30,
        freeMode: true,
        loop: true,
        navigation: {
          nextEl: '.prev',
          prevEl: '.next'
        }
      },
      uploadUrl: null,
      headPhoto: '',
      readImg: null,
      star: false,
      siteInfo: {},
      zindex: null,
      identificationType: '', //专家，学者状态
      presentStatus: null, //认证状态
      presentStatus1: null,
      coopList: [],
      cooperatorList: [],
      subject: [],
      data1Dict: '',
      data2Dict: '',
      data1Subject: '',
      data2Subject: '',
      expertList: [],
      createTime: '',
      acamicList: [],
      policyList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      tempAchievementIds: [],
      tempCertificateIds: [], //图片上传id
      identificationId: null, //认证id
      tempAchievementList: [],
      delAchievementIds: [],
      id: null, //修改id
      userId: null, //用户id
      urlPath: [],
      dictList: [],
      pageNo: 0,
      oneSubjectList: [],
      twoSubjectList: [],
      totalDict: [{ //职称
        dictOne: null,
        dictTwo: null,
        dictThree: null,
      }],
      twoDictList: [],
      twoDictStr: '',
      dictSc1: '',
      dictScThree: [],
      fdsfPrefixPath: '',
      subjectList: [{
        custom: "",
        levelOne: null,
        levelOneName: null,
        levelTwo: null,
        levelTwoName: null,
        levelThreeName: null,
        levelThree: null,
        isCustom: false,
        listTwo: [],
        listThree: [],
      }],
      prevId: 0,
      token: '',
      active: 0,
      publicUrl: publicUrl,
      commonUrl: commonUrl,
      loginUrl: loginUrl,
      ajaxUrl: ajaxUrl,
      rapidModel: false,
      centerDialogVisibles: false,
      dialogVisible: false,
      applyDialogVisible: false,
      indexBookNextVisibles: false,
      value1: '',
      imageUrl: '',
      imageUrl1: '',
      imageUrl2: '',
      imageUrl3: '',
      imageUrl4: '',
      fileList: [],
      fileList1: [],
      fileList01: [],
      form: {
        name: '',
        styles: '学者认证',
        sex: '',
        birTime: '',
        nation: '',
        birthPlace: '',
        email: '',
        education: '',
        company: '',
        companyAddress: '',
        scholrSubject: '',
        dict: '',
        dict2: '',
      },
      options: [{
        value: '学者认证',
        label: '学者认证'
      }, {
        value: '专家认证',
        label: '专家认证'
      }],
      rules: {
        name: [{
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }, ],
        styles: [{
          required: true,
          message: '认证类型不能为空',
          trigger: 'blur'
        }, ],
        sex: [{
          required: true,
          message: '性别不能为空',
          trigger: 'blur'
        }, ],
        nation: [{
          required: true,
          message: '民族不能为空',
          trigger: 'blur'
        }, ],
        birthPlace: [{
          required: true,
          message: '出生地不能为空',
          trigger: 'blur'
        }, ],
        email: [{
            required: true,
            message: '请输入邮箱地址',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: 'blur'
          }
        ],
        company: [{
          required: true,
          message: '公司名称不能为空',
          trigger: 'blur'
        }, ],
        companyAddress: [{
          required: true,
          message: '公司地址不能为空',
          trigger: 'blur'
        }, ],
        education: [{
          required: true,
          message: '学历不能为空',
          trigger: 'blur'
        }, ],
        researchDirection: [{
          required: true,
          message: '研究方向不能为空',
          trigger: 'blur'
        }, ],
        dictOne: [{
          required: true,
          message: '职称不能为空',
          trigger: 'blur'
        }, ],
      },
      formLabelWidth: '120px',
    }
  },
  methods: {
    callback1() {
      window.location.reload();
    },
    callback() {
      this.urlPath = [];
      this.tempAchievementIds = [];
      this.fileList = [];
      $('.zhezhao').hide();
      this.imgFlag = false;
    },

    changeScholr(val) {
      this.prevId = val;
      if (val) {
        this.subjectList[vm.zindex].levelTwo = '';
        this.subjectList[vm.zindex].twoSubjectList = this.subject[val];
        console.log(this.subject[val])
      }
    },
    changeScholr1(val) {
      if (val) {
        this.subjectList[vm.zindex].levelThree = '';
        this.subjectList[vm.zindex].threeSubjectList = this.subject[val];
      }
    },
    //上传成果
    handleRemove(file, fileList) {},
    handlePreview(file) {
      console.log(file);

    },
    handleExceed(files, fileList) {
      //    this.$message.warning(`当前限制选择 ${this.limit} 个文件!`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },

    progress(event, file, fileList) {
      this.fileName = file.name;
      let type = file.raw.type.split('/')[1];
      let size = file.size / 1024 / 1024;
      if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        if (size < 15) {
          this.imgFlag = true;
          this.percent = Math.floor(event.percent);
        }
      }
      if (type == 'mp4' || type == 'avi') {
        if (size < 100) {
          this.imgFlag = true;
          this.percent = Math.floor(event.percent);
        }
      }
    },
    //上传成功
    handleSuc(res, fileList) {
      this.imgFlag = false;
      this.percent = 0;
      var res = res.content;
      for (var p in res) {
        if (this.urlPath < 10) {
          this.urlPath.push({
            'userId': this.userId,
            'name': res[p].name,
            'path': res[p].path,
            'id': res[p].id
          })
          this.tempAchievementIds.push(res[p].id);
        }
      }
    },
    //上传成果失败
    handleError(file, fileList) {
      this.imgFlag = false;
      let type = fileList.raw.type.split('/')[1];
      let size = fileList.size / 1024 / 1024;
      if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        if (size < 15) {
          this.$message({
            message: '上传成果失败！',
            type: 'warning'
          });
        }
      }
      if (type == 'mp4' || type == 'avi') {
        if (size < 100) {
          this.$message({
            message: '上传成果失败！',
            type: 'warning'
          });
        }
      }
    },
    //上传成果删除
    handleRemoveS(id, index) {
      this.urlPath.splice(index, 1);
      this.delAchievementIds.push(id);
      $('.zhezhao').hide();
    },

    clickapply() {
      if (this.token == 'null' || this.token == null) {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else if (this.token == false) {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else if (location.href.indexOf('token') == -1) {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else {
        if (this.presentStatus1 != '0') {
          this.rapidModel = false;
          let msg='认证已完成或正在认证中，无法快速认证！'
          if(this.presentStatus1==6){
             msg='您有其他平台基本信息审核中，无法快速认证！'
          }
          this.$message({
            message: msg,
            type: 'warning'
          });
        } else {
          this.rapidModel = true;
        }
      }
    },
    acaClick() {
      // console.log(this.presentStatus)
      if (this.token == null || this.token == "null") {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else if (this.token == false) {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else if (location.href.indexOf('token') == -1) {
        window.location.href = loginUrl + '/txpy-web/logout';
      } else {
        if (this.presentStatus1 == 1 || this.presentStatus1 == 2) {
          this.applyDialogVisible = true;
        } else {
          this.applyDialogVisible = false;
          this.$message({
            message: '您尚未完成身份认证，暂无法申请评议！',
            type: 'warning'
          });
        }
      }
    },
    submitForm(formName) {
      if (vm.form.styles == '专家认证') {
        this.star = true;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //	            alert('submit!');
          this.dialogFormVisible = false;
          this.centerDialogVisibles = true;
        } else {
          return false;
        }
      });
    },
    //获取专家
    getExpertList() {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/recommend/specialist/list",
        cache: false,
        crossDomain: true,
        async: true,
        dataType: 'json',
        contentType: 'application/json;charset=UTF-8',
        data: {
          pageNo: this.pageNo
        },
        success: (res) => {
          if (res.resultCode == '0') {
            if (res.content.list.results.length >= 1) {
              this.expertList = res.content.list.results.map((item, index) => {
                var headPhotoss = item.tupleTxpyUserInfo.sysUserInfo;
                if (headPhotoss.headPhoto != null) {
                  item.tupleTxpyUserInfo.sysUserInfo.headPhoto = imgPath + item.tupleTxpyUserInfo.sysUserInfo.headPhoto;
                } else {
                  item.tupleTxpyUserInfo.sysUserInfo.headPhoto = '';
                }
                return item;
              })
              // console.log(this.expertList)
            }

          }
        },
      });
    },
    expertClick(id) {
      window.location.href = `/peer_scholars/peer_scholars_detail.html?id=${id}&token=${this.token}`;
    },
    policyClick(id) {
      var reg = new RegExp("(^|&)token=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null)
        context = r[2];
      reg = null;
      r = null;
      var tokens = context == null || context == "" || context == "undefined" ? "" : context;
      window.location.href = `/review_policy/review_policyDeatil.html?id=${id}&token=${tokens}`;
    },
    //获取精评赏析列表
    getAcamicList() {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/recommend/academic/list",
        cache: false,
        crossDomain: true,
        async: true,
        dataType: 'json',
        contentType: 'application/json;charset=UTF-8',
        success: (res) => {
          if (res.resultCode == '0') {
            this.acamicList = res.content.list;
          }
        },
      });
    },
    acamicClick(id, type) {
      var reg = new RegExp("(^|&)token=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null)
        context = r[2];
      reg = null;
      r = null;
      var tokens = context == null || context == "" || context == "undefined" ? "" : context;
      if (type == '1') {
        window.location.href = `/academic_achievements/study_achievement_opusDetail.html?step=1&id=${id}&token=${tokens}`;
      } else if (type == "0") {
        window.location.href = `/academic_achievements/study_achievement_otherDetail.html?step=1&id=${id}&token=${tokens}`;
      } else if (type == '2') {
        window.location.href = `/academic_achievements/study_achievement_workDetail.html?step=1&id=${id}&token=${tokens}`;
      } else if (type == '9') {
        window.location.href = `/academic_achievements/study_achievement_patentDetail.html?step=1&id=${id}&token=${tokens}`;
      } else {
        window.location.href = `/academic_achievements/study_achievement_paperDetail.html?step=1&id=${id}&token=${tokens}`;
      }

    },
    //获取政策咨询列表
    getpolicyList() {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/recommend/content/list",
        cache: false,
        crossDomain: true,
        async: true,
        dataType: 'json',
        data: {
          type: 1,
          pageNo: this.pageNo
        },
        contentType: 'application/json;charset=UTF-8',
        success: (res) => {
          if (res.resultCode == '0') {
            if (res.content.list) {
              this.policyList = res.content.list.map(item => {
                item.releaseTime=this.$global.transferYear(item.releaseTime)
                return item;
              });
            }
          }
        },
      });
    },
    //获取合作单位
    getCooperatorList() {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/sites/cooperator/list",
        cache: false,
        crossDomain: true,
        async: true,
        dataType: 'json',
        data: {
          pageNo: this.pageNo
        },
        contentType: 'application/json',
        success: (res) => {
          let cooperator = [];
          if (res.content.cooperator.list) {
            this.coopList = res.content.cooperator.list;
            console.log(this.coopList)
          }
        },
      });
    },

    //提交表单
    uploadClick() {
      let fileListC = [];
      if (vm.form.styles == '专家认证') {
        var type = 1;
        fileListC = this.fileList01;
        if (!this.urlPath.length) {
          this.$message({
            message: '请上传成果',
            type: 'warning'
          });
          return;
        }
      } else {
        var type = 2;
        fileListC = this.fileList1;
      }
      vm.totalDict.forEach(item => {
        vm.twoDictList.push(item.dictThree);
        vm.twoDictStr = vm.twoDictList.join(',');
      })
      for (var p in this.subject) {
        this.subjectList.forEach(item => {
          this.subject[p].forEach(sub => {
            if (sub.id == item.levelOne) {
              item.levelOneName = sub.subjectName;
            }
            if (sub.id == item.levelTwo) {
              item.levelTwoName = sub.subjectName;
            }
            if (sub.id == item.levelThree) {
              item.levelThreeName = sub.subjectName;
            }
          })
        })
      }
      var userIdentificationDto = {
        "sysUserAchievementArray": vm.urlPath,
        "sysUserCertificateArray": fileListC,
        "sysUserTitleArray": vm.totalDict,
        "tempAchievementIds": vm.tempAchievementIds,
        "tempCertificateIds": vm.tempCertificateIds,
        "delAchievementIds": this.delAchievementIds, //删除的上传成果id
        "userIdentification": {
          "id": vm.identificationId,
          "type": type,
          "userId": vm.userId
        },
        "sysUserSubjectArray": this.subjectList,
        "userInfoDto": {
          "id": vm.id,
          "age": '',
          "addr": vm.form.birthPlace,
          "userId": vm.userId,
          "city": "",
          "province": "",
          "unit": vm.form.company,
          "unitAddr": vm.form.companyAddress,
          "qq": "",
          "sex": vm.form.sex,
          "birthday": vm.form.birTime,
          "nation": vm.form.nation,
          "nationPlace": vm.form.companyAddress,
          "education": vm.form.education,
          "tel": "",
          "mailing": "",
          "mailNum": "",
          "department": "",
          "title": vm.twoDictStr,
          "researchField": "",
          "hideStatus": null
        },
        'nickname': vm.form.name,
        'email': vm.form.email
      }

      $.ajax({
        type: "post",
        url: ajaxUrl + "/txpy-web/user/identification",
        cache: false,
        crossDomain: true,
        headers: {
          "Authorization": this.token
        },
        async: true,
        dataType: 'json',
        contentType: 'application/json;charset=UTF-8',
        data: JSON.stringify(userIdentificationDto),
        success: (res) => {
          if (res.resultCode == 200) {
            this.$message({
              message: '认证提交成功！',
              type: 'success'
            });
          }
          setTimeout(function () {
            window.location.reload();
            this.centerDialogVisibles = false;
            this.rapidModel = false;
          }, 2000)
        },
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showActive(index) {
      this.active = index;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },

    //上传资质证书
    handleSucc(res, file) {
      var res = res.content;

      for (var p in res) {
        this.fileList1.push({
          url: vm.fdsfPrefixPath + res[p].path,
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        })
        this.fileList01.push({
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        })
        this.tempCertificateIds.push(res[p].id)
      }
    },
    handleAvatarSuccess(res, file) {

    },
    handleAvatarSuccess1(res, file) {
      var res = res.content;
      for (var p in res) {
        this.tempCertificateIds.push(res[p].id);
        let obj = {
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        };
        this.fileList01.splice(0, 1, obj);

      }
      this.imageUrl1 = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess2(res, file) {
      var res = res.content;
      for (var p in res) {
        this.tempCertificateIds.push(res[p].id)

        let obj = {
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        };
        this.fileList01.splice(1, 1, obj);
      }

      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess3(res, file) {
      var res = res.content;
      for (var p in res) {
        this.tempCertificateIds.push(res[p].id)

        let obj = {
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        };
        this.fileList01.splice(2, 1, obj);
      }

      this.imageUrl3 = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess4(res, file) {
      var res = res.content;
      for (var p in res) {
        this.tempCertificateIds.push(res[p].id)

        let obj = {
          id: res[p].id,
          context: res[p].path,
          createTime: res[p].createTime,
          title: '',
          userId: vm.userId,
          updateTime: '',
        };
        this.fileList01.splice(3, 1, obj);
      }
      this.imageUrl4 = URL.createObjectURL(file.raw);
    },

    //资质证书
    handleRemove2(file, index) {
      this.fileList1.splice(index, 1);
      this.fileList01.splice(index, 1);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    beforeAvatarUpload1(file) {
      const type = file.type.split('/')[1];
      this.isLt15M = file.size / 1024 / 1024 < 15;
      this.isLt100M = file.size / 1024 / 1024 < 100;
      console.log(this.urlPath.length)
      if (this.urlPath.length > 9) {

        $('.zhezhao').show();
        this.$message.warning(`当前选择文件已达到最大限度!22222222222222222222`)
        return false;
      } else {
        $('.zhezhao').hide();
        if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
          if (!this.isLt15M) {
            this.$message.warning('上传文件大小不能超过 15MB!');
          }
        }
        if (type == 'mp4' || type == 'avi') {
          if (!this.isLt100M) {
            this.$message.warning('上传文件大小不能超过 100MB!');
          }
        }
        return this.isLt15M || this.isLt100M;
      }
    },
    //上传资质证书结束
    //获取认证接口
    IdentificationInfo() {
      if (!this.isLogin) {
        return;
      }
      if (this.token == 'false') {
        return;
      }
      if (this.token != 'null' || this.token == true) {
        $.ajax({
          type: "get",
          url: ajaxUrl + "/txpy-web/user/getUserIdentificationInfo",
          cache: false,
          crossDomain: true,
          headers: {
            "Authorization": this.token
          },
          async: true,
          dataType: 'json',
          success: (res) => {
            this.identificationId = res.content.result.identificationId;
          }
        })
      }
    },
    //获取用户信息
    getUserInfo() {
      var token = '';
      var query = decodeURI(window.location.search.substring(1));
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].indexOf("token") != -1) {
          var pair = vars[i].split('=');
          token = pair[1];
        }
      }
      localStorage.setItem('token', JSON.stringify(token))
      if (!this.isLogin) {
        return;
      } else {
        if (!this.isLogin) {
          return;
        }
        if (this.token == 'false' || this.token == false) {
          return;
        }
        if (this.token != 'null' || this.token == true) {
          $.ajax({
            type: "get",
            url: ajaxUrl + "/txpy-web/user/getUserInfo",
            cache: false,
            crossDomain: true,
            headers: {
              "Authorization": token
            },
            async: true,
            dataType: 'json',
            success: (res) => {
              this.userId = res.content.result.id;
              this.id = res.content.result.sysUserInfo.id;
              this.identificationType = res.content.result.identificationType;
              this.presentStatus1 = res.content.result.presentStatus;
              if(this.presentStatus1<3){
                vm.platStatus(token,res=>{
                  if(res!=0){//返回几有几个平台在审核中，等于0可以编辑
                    this.presentStatus1="6"
                  }
                })
              }
            },
          });


        }
      }
    },
    platStatus(token,callback){
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/user/platformAuthenticationStatus",
        cache: false,
        crossDomain: true,
        headers: {
          "Authorization": token
        },
        async: true,
        dataType: 'json',
        success: (res) => {
          callback(res.content.result)
        },
      });
    },
    //判断是否是第一次登陆
    isFirstLogin() {
      var reg = new RegExp("(^|&)token=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null)
        context = r[2];
      reg = null;
      r = null;
      var token = context == null || context == "" || context == "undefined" ? "" : context;
      if (!this.isLogin) {
        return;
      }
      if (this.token == 'false' || this.token == false) {
        return;
      }
      if (this.token != 'null' || this.token == true) {
        $.ajax({
          type: "get",
          url: ajaxUrl + "/txpy-web/user/isFirstLogin",
          cache: false,
          crossDomain: true,
          headers: {
            "Authorization": token
          },
          async: true,
          dataType: 'json',
          success: (res) => {
            if (res.content.isFirstLogin) {
              this.rapidModel = false;
            } else {
              this.rapidModel = true;
            }
          },
        });
      }
    },
    styleChange() {
      this.totalDict = [{ //职称
        dictOne: null,
        dictTwo: null,
        dictThree: null,
      }]
      this.subjectList = [{
        custom: "",
        levelOne: null,
        levelOneName: null,
        levelTwo: null,
        levelTwoName: null,
        levelThreeName: null,
        levelThree: null,
        isCustom: false,
        listTwo: [],
        listThree: [],
      }];
      this.form.name = '';
      this.form.sex = '';
      this.form.birTime = '';
      this.form.nation = '';
      this.form.birthPlace = '';
      this.form.email = '';
      this.form.company = '';
      this.form.companyAddress = '';
      this.form.education = '';
    },
    //获取学科
    getSubjectList() {
      if (!this.isLogin) {
        return;
      }
      if (this.token == 'false') {
        return;
      }
      if (this.token != 'null' || this.token == true) {
        $.ajax({
          type: "get",
          url: ajaxUrl + "/txpy-web/subject/list",
          cache: false,
          crossDomain: true,
          headers: {
            "Authorization": this.token
          },
          data: {
            prevId: this.prevId
          },
          async: true,
          dataType: 'json',
          success: (res) => {
            if (this.prevId == 0) {
              this.oneSubjectList = res.content.list;
            } else {
              this.twoSubjectList = res.content.list;
            }

          }
        })
      }
    },
    //获取树学科
    gettreeList() {
      if (!this.isLogin) {
        return;
      }
      if (this.token == 'false') {
        return;
      }
      if (this.token != 'null' || this.token == true) {
        $.ajax({
          type: "get",
          url: ajaxUrl + "/txpy-web/subject/treeList",
          cache: false,
          crossDomain: true,
          headers: {
            "Authorization": this.token
          },
          async: true,
          dataType: 'json',
          success: (res) => {
            this.subject = res.content.list;
          }
        })
      }
    },



    addScholr() {
      var data = {
        custom: "",
        levelOne: null,
        levelOneName: null,
        levelTwo: null,
        levelTwoName: null,
        levelThreeName: null,
        levelThree: null,
        isCustom: false,
        listTwo: [],
        listThree: [],
      }
      this.subjectList.push(data);
    },
    delSubject(index) {
      this.subjectList.splice(index, 1);
    },
    //获取职称
    getDictList() {
      if (!this.isLogin) {
        return;
      }
      if (this.token == 'false') {
        return;
      }
      if (this.token != 'null' || this.token == true) {
        $.ajax({
          type: "post",
          url: ajaxUrl + "/txpy-web/zw/title/list",
          cache: false,
          crossDomain: true,
          headers: {
            "Authorization": this.token
          },
          async: true,
          dataType: 'json',
          success: (res) => {
            this.dictList = res.content.list;
          }
        })
      }
    },

    //职称1
    dictScChange1(val) {
      this.dictSc1 = val;
      this.dictScThree = [];
    },
    //职称2
    dictScChange2(val) {
      this.dictScThree = [];
      this.dictList.forEach(item => {
        if (item.id == this.dictSc1) {
          item.elementary.forEach(sub => {
            if (sub.gradeId == val) {
              this.dictScThree.push({
                titleName: sub.titleName,
                id: sub.id
              })
            }
          })
          item.middle.forEach(sub => {
            if (sub.gradeId == val) {
              this.dictScThree.push({
                titleName: sub.titleName,
                id: sub.id
              })
            }
          })
          item.senior.forEach(sub => {
            if (sub.gradeId == val) {
              this.dictScThree.push({
                titleName: sub.titleName,
                id: sub.id
              })
            }
          })
          item.subSenior.forEach(sub => {
            if (sub.gradeId == val) {
              this.dictScThree.push({
                titleName: sub.titleName,
                id: sub.id
              })
            }
          })
        }
      })
    },
    dictScChange3() {
      this.dictScThree = [];
    },
    addDict() {
      var dic = {
        dictOne: null,
        dictTwo: null,
        dictThree: null,
      }
      this.totalDict.push(dic);
    },
    delDict(index) {
      this.totalDict.splice(index, 1);
    },
    swiper() {

    },

    //站点
    getsiteInfo() {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/site/info/getsiteinfo",
        cache: false,
        crossDomain: true,
        async: true,
        dataType: 'json',
        contentType: 'application/json;charset=UTF-8',
        success: (res) => {
          if (res.resultCode == 0) {
            this.siteInfo = res.content.siteInfo;
          }
        },
      });
    },
  },
  computed: {
    isLogin() {
      return location.href.indexOf('token') != -1;
    },
    //  showSwiper () {
    //		   return this.coopList.length
    //		 }
  },
  mounted() {
    //		this.token = JSON.parse(localStorage.getItem('token'));
    this.token = getQueryVariable('token');
    if (sessionStorage.getItem("userInfo")) {
      this.presentStatus = JSON.parse(sessionStorage.getItem("userInfo")).presentStatus;
    }
    this.getUserInfo();
    this.isFirstLogin();
    this.getSubjectList();
    this.getDictList();
    this.IdentificationInfo();
    this.getExpertList();
    this.getAcamicList();
    this.getpolicyList();
    //  this.swiper();
    this.gettreeList();
    this.getCooperatorList();
    this.getsiteInfo();
    this.uploadUrl = ajaxUrl + `/txpy-web/test/common/upload?token=${this.token}`;
  },
  created() {

  },

})
