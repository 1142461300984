<template>
	<div class="footer_Warp">
		<div class="footer_Top">
			<div class="footer_TopMain">
				<img src="../img/footer.png" class="footerIconf"/>
				<span class="footerLine"></span>
				<ul class="footerListOne">
					<li>出版物经营许可证</li>
					<li>网络出版服务许可证</li>
					<li>© 2018&nbsp;&nbsp;&nbsp;&nbsp;百家在线数据有限公司</li>
				</ul>
				<span class="footerLine"></span>
				<ul class="footerListTwo">
					<li>商务合作</li>
					<li>电话：&nbsp;010-52485937</li>
					<li>邮箱：&nbsp;support@cnknowledge.cn</li>
				</ul>
			</div>
		</div>
		<div class="footer_Bot">
			<div class="footer_BotMain">
				互联网出版许可证 <a style="color:#fff;margin-left:10px;" href="http://www.beian.miit.gov.cn" target ="_blank">京ICP备19039670号-1</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data:function(){
		return {

		}
	},
	methods:{
		
	}

}
</script>
