<template>
	<div class="header_Warp">
		<div class="header_welcome">
			<div class="header_welcomeMain">
				<p>欢迎来到同行评议平台官网！</p>
				<div>
					<!-- <img src="../img/phone.png"/> -->
					<span>咨询热线：010-52485937</span>
				</div>
			</div>
		</div>
		<div class="header_search">
			<div class="header_searchMain">
				<div class="header_searchMainLeft">
					<img src="../img/header.png" class="headerIconf" @click="FnLink"/>
					<img src="../img/logoword.png" class="headerWord" @click="FnLink"/>
				</div>
				<div class="header_searchMainRight">
					<div class="header_searchMainSearch">
						<div id="selectList" @click="FnFlag">
							<span class="SearchWord">{{flagWord}}</span>
							<img src="../img/jiantou.png" class="searchIconf"/>
							<ul class="SearchList" v-if="flag">
								<li @click="flagWord = '作者'">作者</li>
								<li @click="flagWord = '学术成果'">学术成果</li>
								<li @click="flagWord = '评价报告'">评价报告</li>
							</ul>
						</div>
						<div class="searchInput">
							<span class="SearchLine"></span>
							<input type="text" class="search" placeholder="请输入名称" v-model="searchWord" />
							<img src="../img/serch.png" class="searchIconf1" @click="FnSearch"/>
						</div>
					</div>
					<div class="header_searchMainSearchR" v-if="token=='null' || token==null || !isLogin || token=='false'" >
						<span @click="login()"><i>登录</i></span>
						<span class="SearchLine"></span>
						<a :href="regUrl + '/auth/registerTxpy'+'?platform='+clientId"><i>注册</i></a>
						<span class="SearchLine"></span>
						<a @click="login()"><i>消息</i></a>
					</div>
				
					<div class="header_searchMainSearchR" v-if="token!=null && token!='null' && isLogin && token!='false'">
						<a v-if="count<1" :href="'/personal_center/information_list.html?token='+token"><i>消息</i></a>
						<a v-if="count>0" :href="'/personal_center/information_list.html?token='+token">
							<el-badge :value="count" :max="99" class="item">
							  	<i style="margin-right: 6px;">消息</i>
							</el-badge>
						</a>
						<span class="SearchLine"></span>
						<span style="cursor: pointer;"><span @click="personClick()">个人中心</span></span>
						<span class="SearchLine"></span>
						<span @click="logout"><i>安全退出</i></span>
					</div>
				</div>
			</div>
		</div>
		<div class="header_nav">
			<ul class="navList">
				<li class="navListClick"><a :href="commonUrl + `?token=${token}`">首页</a></li>
				<li><a :href="`/peer_scholars/peer_scholars_list.html?token=${token}`">同行学者</a></li>
				<li><a :href="`/academic_achievements/study_achievement.html?token=${token}`">学术成果</a></li>
				<li><a :href="`/review_policy/review_policy.html?token=${token}`">政策资讯</a></li>
				<li><a :href="`/about_us/platform_profile.html?token=${token}`">关于我们</a></li>
			</ul>

		</div>
		<div class="header_banner">
			<img src="../img/banner.png"/>
		</div>
		
	</div>
</template>

<script>
export default {
	data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			loginUrl:loginUrl,
			regUrl:regUrl,
			searchWord:'',
			flag : false,
			flagWord : '评价报告',
			isshow:false,
			token:'',
			clientId:'',
			identificationType:null,
			count:0,
		}
	},
	props:["flagWords","searchWords"],
	mounted:function(){
		var that =this;
		this.getUserInfo();
		this.newNews();
		this.fn();
		$("body").click(function(e) {
			if(!$(e.target).closest("#selectList").length) {
				that.flag = false;
				$('.searchIconf').css('transform','rotate(0)')
			}
		});
		if(this.flagWords){
			this.flagWord = this.flagWords;
		}else{
			this.flagWord = '评价报告'
		}
		this.searchWord =this.searchWords;
	},
	computed:{
		isLogin: function(){
			return location.href.indexOf('token')!=-1
		}
	},
	methods:{
		FnLink:function(){
			location.href = commonUrl + `?token=${this.token}`;
		},
		FnFlag:function(){
			this.flag = !this.flag;
			if(this.flag){
				$('.searchIconf').css('transform','rotate(180deg)')
			}else{
				$('.searchIconf').css('transform','rotate(0)')
			}
		},
		fn(){
			$.ajax({
				type: "get",
				url: ajaxUrl + "/txpy-web/test/common/getRegisterParameter",
				cache: false,
				crossDomain:true,
				dataType: 'json',
				// headers:{"Authorization":this.token,'Zw-Type':'ajax/json'},
				success:(res) => {
					if(res.resultCode == 200){
						this.clientId = res.content.clientId;
						// 	 var obj={
						// 		clientId:res.content.result.identificationType,
						// 		  fdsfPrefixPath:res.content.fdsfPrefixPath,
						// 		  mobile:res.content.result.mobile,
						// }
						//   var jsonStr = JSON.stringify(obj);
						//   sessionStorage.setItem('userInfo',jsonStr);
					}
				},
			
			});
		},
		//获取用户信息
		getUserInfo(){
			if (!this.isLogin) {
				return;
			}
			if (this.token=='false') {
				return;
			}
			if (this.token !='null' || this.token==true) {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/user/getUserInfo",
					cache: false,
					crossDomain:true,
					dataType: 'json',
					headers:{"Authorization":this.token,'Zw-Type':'ajax/json'},
					success:(res) => {
						if(res.resultCode == 200){
							this.isshow=false;
							this.identificationType = res.content.result.identificationType;
						 	var obj={
                          		identificationType:res.content.result.identificationType,
                          		fdsfPrefixPath:res.content.fdsfPrefixPath,
                          		mobile:res.content.result.mobile,
                          		presentStatus:res.content.result.presentStatus,
                          		userId:res.content.result.id,
	                        }
						  	var jsonStr = JSON.stringify(obj);
							sessionStorage.setItem('userInfo',jsonStr);
							  
						}
						if(res.resultCode == 1001){
							this.$message({
							  message: '登录超时，请重新登录',
							  type: 'warning'
							});
							setTimeout(function(){
								window.location.href = loginUrl + '/txpy-web/logout';
							},1000)
						}
					},
					error:(res)=>{
						if(res.responseJSON.resultCode == 1001){
							this.$message({
					          message: '登录超时，请重新登录',
					          type: 'warning'
					        });
							setTimeout(function(){
								window.location.href = loginUrl + '/txpy-web/logout';
							},2000)
						}
					}
				});
			}
				
		},
		
		//消息
		newNews(){
			if (!this.isLogin) {
				return;
			}
			if (this.token=='false') {
				return;
			}
			if (this.token !='null' || this.token==true) {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/message/unread",
					cache: false,
					crossDomain:true,
					dataType: 'json',
					headers:{"Authorization":this.token,'Zw-Type':'ajax/json'},
					success:(res) => {
						if(res.resultCode == 200){
							this.count = res.content.count;  
						}
					},
				});
			}
		},
		
		FnSearch:function(){
			if (!this.searchWord) {
				this.searchWord='';
			}
			window.location.href = encodeURI('/search_list/search_list.html?token='+this.token+'&flagWord='+this.flagWord+'&searchWord='+this.searchWord);
		},
		personClick(){
			window.location.href = '/personal_center/expert_management.html?token='+this.token;
		},
		logout(){
			localStorage.removeItem('token');
			sessionStorage.removeItem('userInfo');
			this.isshow=true;
			window.location.href = commonUrl + '?token=null';
		},
		login(){
			window.location.href = loginUrl + '/txpy-web/logout';			
		},
		
	},
	created(){
//		this.token = JSON.parse(localStorage.getItem('token'));
		this.token = getQueryVariable('token');
//		this.getUserInfo();
	},
}
</script>

<style scoped>

</style>
