<template>
    <div>	
  	<el-form :model="applyForm"  :rules="applyRules" ref="ruleApply" style="text-align:left;">
	    <el-form-item label="类型" :label-width="formLabelWidth" prop="styles">
	      <el-select v-model="applyForm.styles" @change="changType" style="width:300px">
	        <el-option 
	        	v-for="item in applyOptions"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value"
			      :disabled="item.disabled">
	        </el-option>
	      </el-select>
	    </el-form-item>
	    <div v-if="applyForm.styles == '1'">
	    	<el-form-item label="标题" :label-width="formLabelWidth" prop="paperTitle">
		      <el-input v-model="applyForm.paperTitle" autocomplete="off" style="width:350px;" placeholder="请输入标题"></el-input>
		    </el-form-item>
		    <el-form-item label="关键词" :label-width="formLabelWidth" prop="paperKeyWord">
		      <el-input v-model="applyForm.paperKeyWord" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord1" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord2" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord3" autocomplete="off" style="width:100px;"></el-input> 
		    </el-form-item>
		    <el-form-item label="基金项目" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.paperProject" autocomplete="off" style="width:350px;"></el-input>
		    </el-form-item>
		    <el-form-item label="第一作者" :label-width="formLabelWidth" prop="firstAuthor">
		      <el-input v-model="applyForm.firstAuthor" autocomplete="off" style="width:350px;"></el-input>
		    </el-form-item>
		    <el-form-item label="其他作者" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherEditor" autocomplete="off" style="width:350px;"></el-input>
		      <p style="font-size: 14px;color: red;">（如有多个作者，请用逗号隔开）</p>
		    </el-form-item>
		    <el-form-item label="通讯作者" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.paperTelAuthor" autocomplete="off" style="width:350px;"></el-input>
		    </el-form-item>
		    <el-form-item label="摘要" :label-width="formLabelWidth" prop="paperzhaiyao">
		      <el-input type="textarea" v-model="applyForm.paperzhaiyao" style="width:410px;"  minlength="150" maxlength="400" :rows="5"></el-input>
		    </el-form-item>
	    </div>	    
	    
  			    
	    <div v-else-if="applyForm.styles == '2'">
	    	<el-form-item label="图书名称" :label-width="formLabelWidth" prop="paperTitle">
		      <el-input v-model="applyForm.paperTitle" autocomplete="off" style="width:350px;" placeholder="请输入图书名称"></el-input>
		    </el-form-item>
		    <el-form-item label="图书分类" :label-width="formLabelWidth" prop="bookstyle">
		      <el-input v-model="applyForm.bookstyle" autocomplete="off" style="width:350px;" placeholder="请输入图书类型"></el-input>
		    </el-form-item>
		    <el-form-item label="图书字数" :label-width="formLabelWidth" prop="bookNumber">
		      <el-input type="number" v-model="applyForm.bookNumber" autocomplete="off" style="width:350px;" placeholder="请输入图书字数"></el-input>
		    </el-form-item>
		    <el-form-item label="出版时间" :label-width="formLabelWidth" prop="publishTime">
		      	<el-date-picker
		      	  v-model="applyForm.publishTime"
			      type="date"
			      placeholder="选择日期"
			      format="yyyy-MM-dd"
      			  value-format="yyyy-MM-dd"
			      @change="changeDate">
			    </el-date-picker>
		    </el-form-item>
		    <el-form-item label="第一主编" :label-width="formLabelWidth" prop="firstAuthor">
		      <el-input v-model="applyForm.firstAuthor" autocomplete="off" style="width:350px;" placeholder="请输入第一主编姓名"></el-input>
		    </el-form-item>
		    <el-form-item label="第二主编" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.paperOther" autocomplete="off" style="width:350px;" placeholder="请输入第二主编姓名"></el-input>
		    </el-form-item>
		    <el-form-item label="其他主编" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherEditor" autocomplete="off" style="width:350px;" placeholder="请输入其他主编姓名"></el-input>
		      <p style="font-size: 14px;color: red;">（如有多个主编，请用逗号隔开。）</p>
		    </el-form-item>
		    <el-form-item label="摘要" :label-width="formLabelWidth" prop="paperzhaiyao">
		      <el-input type="textarea" v-model="applyForm.paperzhaiyao" style="width:410px;" :rows="5"></el-input>
		    </el-form-item>
	    </div>
	    	    
	    <div v-else-if="applyForm.styles == '0' || applyForm.styles == '9'">
	    	<el-form-item label="标题" :label-width="formLabelWidth" prop="paperTitle">
		      <el-input v-model="applyForm.paperTitle" autocomplete="off" style="width:350px;"></el-input>
		    </el-form-item>
		    <el-form-item label="作者" :label-width="formLabelWidth" prop="otherEditor">
		      <el-input v-model="applyForm.otherEditor" autocomplete="off" style="width:350px;"></el-input>
		      <p style="font-size: 14px;color: red;">（如有多个作者，请用逗号隔开。）</p>
		    </el-form-item>
		    <el-form-item label="摘要" :label-width="formLabelWidth" prop="paperzhaiyao">
		      <el-input type="textarea" v-model="applyForm.paperzhaiyao" style="width:410px;" :rows="5"></el-input>
		    </el-form-item>
	    </div>
	    	    
	    <div v-else>
	    	<el-form-item label="标题" :label-width="formLabelWidth" prop="paperTitle">
		      <el-input v-model="applyForm.paperTitle" autocomplete="off" style="width:350px;" placeholder="请输入标题"></el-input>
		    </el-form-item>
		    <div v-for="(item,index) in applyForm.authorUnitArr">
		    	<el-form-item label="作者" :label-width="formLabelWidth" :prop="`authorUnitArr.${index}.author`"  :rules="authorUnitRules.author">
			      <el-input v-model="item.author" autocomplete="off" style="width:350px;" placeholder="请输入作者姓名"></el-input>
			    </el-form-item>
			    <el-form-item label="单位" :label-width="formLabelWidth" :prop="`authorUnitArr.${index}.unit`" :rules="authorUnitRules.unit">
			      <el-input v-model="item.unit" autocomplete="off" style="width:350px;" placeholder="请输入单位"></el-input>
			      <el-button v-if="applyForm.authorUnitArr.length>1" @click="delAuthorUnitArr(index)">删除</el-button>
			      <el-button @click="addAuthorUnit">+</el-button>
			      <p style="font-size: 14px;color: red;">（如有多个作者和单位，请点击“+”填写。）</p>
			    </el-form-item>
		    </div>
		    <el-form-item label="研究方向" :label-width="formLabelWidth" prop="researchDir">
				<div v-for="(sub,index) in firstSubjectList" :key="index" style="margin-top:10px;">
		    		<el-select v-model="sub.levelOne" style="width:150px;" @focus="zindex=index" @change="changeScholr"  placeholder="请选择">
				        <el-option v-for="(item,index) in oneSubjectList" :label="item.subjectName" :value="item.id" :key="index"></el-option>
				    </el-select>
				    <el-select v-model="sub.levelTwo" @focus="zindex=index" @change="changeScholr1" style="width:150px;" placeholder="请选择">
				        <el-option v-for="(item,index) in sub.twoSubjectList" :label="item.subjectName" :value="item.id" :key="index"></el-option>
				    </el-select>
				    <el-select v-if="sub.threeSubjectList" v-model="sub.levelThree" style="width:150px;">
				        <el-option v-for="(item,index) in sub.threeSubjectList" :label="item.subjectName" :value="item.id" :key="index"></el-option>
				    </el-select>							    
				    <el-input v-if="!sub.threeSubjectList" v-model="sub.custom" autocomplete="off" style="width:150px;" placeholder="请选择"></el-input>
				    <el-button v-if="firstSubjectList.length>1" @click="delfirstSubject(index)">删除</el-button>							    
				</div>
				<el-button @click="addSchor()" style="margin-top:10px;">+</el-button>
				<p style="font-size: 14px;color:red;">（如果您有更多研究方向，请点击“+”选择填写）</p>
		    </el-form-item>
		    <el-form-item label="关键词" :label-width="formLabelWidth" prop="paperKeyWord">
		      <el-input v-model="applyForm.paperKeyWord" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord1" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord2" autocomplete="off" style="width:100px;"></el-input>
		      <el-input v-model="applyForm.paperKeyWord3" autocomplete="off" style="width:100px;"></el-input> 
		    </el-form-item>
		    <el-form-item label="中图分类号" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherBookStyle" autocomplete="off" style="width:350px;" placeholder="请输入中图分类号"></el-input>
		    </el-form-item>
		    <el-form-item label="学术刊发" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherBookPublication" autocomplete="off" style="width:350px;" placeholder="请输入学术刊发"></el-input>
		    </el-form-item>
		    <el-form-item label="收录证书编号" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherCertificateNum" autocomplete="off" style="width:350px;" placeholder="请输入收录证书编号"></el-input>
		    </el-form-item>
		    <el-form-item label="版权证书编号" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.otherCopyright" autocomplete="off" style="width:350px;" placeholder="请输入版权证书编号"></el-input>
		    </el-form-item>
		    <el-form-item label="基金项目" :label-width="formLabelWidth">
		      <el-input v-model="applyForm.paperProject" autocomplete="off" style="width:350px;" placeholder="请输入基金项目"></el-input>
		    </el-form-item>
		    <el-form-item label="摘要" :label-width="formLabelWidth" prop="paperzhaiyao">
		      <el-input type="textarea" v-model="applyForm.paperzhaiyao" style="width:410px;" :rows="5"></el-input>
		    </el-form-item>
	    </div>
	    						    
   </el-form>
  <div slot="footer" class="dialog-footer" style="text-align: center;">
    <el-button type="primary" @click="indexApplyNextVisible('ruleApply')">保存</el-button>
  </div>

					
</div>



</template>

<script>
var vm;
export default {
	props:['message'],
	data:function(){
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			zindex:null,
			ajaxUrl:ajaxUrl,
			prevId:0,
			subject:[],
			token:'',
			academic:{},
			status:'',
			flag:'',
			articleAttachmentId:'',
			articleAttachment:'',
			authorUnitRules:{
				 author:[
		            { required: true, message: '作者不能为空', trigger: 'blur' },
		        ],
		        unit:[
		            { required: true, message: '单位不能为空', trigger: 'blur' },
		        ],
			},
			userId:null,
			type:null,                         //学术成果类型
			centerDialogVisibles:false,
			imageUrl: '',
			fileList: [],
			thesis:[],
			oneSubjectList:[],
			twoSubjectList:[],
			keyWordArr:[],
			subjectList:[
				{
					custom:"",
  					levelOne:null,
  					levelTwo:null,
  					levelThree:null,
  					isCustom:false,
		            listTwo:[],
		            listThree:[],	
				}
			],
			firstSubjectList:[
				{
					custom:"",
  					levelOne:null,
  					levelTwo:null,
  					levelThree:null,
  					levelOneName :null,
  					levelTwoName:null,
  					levelThreeName:null,
  					isCustom:false,
		            listTwo:[],
		            listThree:[],	
				}
			],
			dictName:'',
			ruleForm:{
				levelId:'',
				dictTitle:'',
				reportNum:'',
			},
			rules:{
				levelId: [
		            { required: true, message: '评议专家研究方向不能为空', trigger: 'change'}
		        ],
		        dictTitle: [
		            { required: true, message: '评议专家职称级别不能为空', trigger: 'change'}
		        ],
		        reportNum:[
		            { required: true, message: '评价报告数量不能为空', trigger: 'blur'}
		        ],
			},
	        applyForm: {
	        	paperTitle:'',  
	        	paperKeyWord:'', 
	        	paperKeyWord1:'',
	        	paperKeyWord2:'',
	        	paperKeyWord3:'',
	        	paperKeyWord4:'',
		        styles:1,
		        bookstyle:'',  //著作中的图书分类
		        bookNumber:'',  //著作中的图书字数
		        publishTime:'',  // 著作的出版时间
		        paperProject:'', //论文基金项目
	        	paperOther:'',  //	论文模板其他作者
	        	paperTelAuthor:'', //论文通讯作者	
		        firstAuthor:'',  //论文第一作者
		        paperzhaiyao:'', //论文摘要
		        otherEditor:'',  //著作的其他主编
		        patentTitle:'',   //专利标题
		        researchDir:'',   //四个字模板的研究方向
		        researchDir2:'',
		        researchDir3:'',
		        otherBookStyle:'',  //四个字模板的中图分类号
		        
				authorUnitArr:[{
					author:null,
					unit:null,
				}],
	        },
	        applyOptions: [{
            	value: 1,
            	label: '论文'
	        },{
            	value: 2,
            	label: '著作'
	        },{
            	value: 9,
            	label: '专利'
	        },{
            	value: 4,
            	label: '研究报告'
	        },{
            	value: 3,
            	label: '工作总结'
	        },{
            	value: 5,
            	label: '病例病案'
	        },{
            	value: 10,
            	label: '教案教程'
	        },{
            	value: 6,
            	label: '工程方案'
	        },{
            	value: 7,
            	label: '设计方案'
	        },{
            	value: 8,
            	label: '科技报告'
	        },{
            	value: 0,
            	label: '其他'
	        }],
	         applyRules: {
	         	paperTitle: [
		            { required: true, message: '论文标题不能为空', trigger: 'blur' },
		        ],
		        styles: [
		            { required: true, message: '认证类型不能为空', trigger: 'blur' },
		        ],
		        paperKeyWord: [
		            { required: true, message: '关键词不能为空', trigger: 'blur' },
		        ],
		        firstAuthor: [
		            { required: true, message: '第一作者不能为空', trigger: 'blur' },
		        ],
		        otherEditor: [
		            { required: true, message: '作者不能为空', trigger: 'blur' },
		        ],
		        paperzhaiyao: [
		            { required: true, message: '摘要不能为空', trigger: 'blur' },
		        ],
		        bookstyle: [
		            { required: true, message: '图书类型不能为空', trigger: 'blur' },
		        ],
		        bookNumber: [
		            { required: true, message: '图书字数不能为空', trigger: 'blur' },
		        ],
		        publishTime: [
		            { required: true, message: '出版时间不能为空', trigger: 'blur' },
		        ],
		        researchDir:[
		            { required: true, message: '研究方向不能为空', trigger: 'change' },
		        ],
	         },
	        formLabelWidth: '100px',
		}
	},
	methods:{
		changType(val){			
			this.type = val;
			this.applyForm.styles = val;
//			this.applyForm.paperTitle = '';
//			this.applyForm.firstAuthor = '';
//			this.applyForm.paperOther = '';
//			this.applyForm.paperzhaiyao = '';
//			this.applyForm.otherEditor = '';
//			this.getAceInfo();
		},
		changeScholr(val){
	    	this.prevId = val;
	    	this.applyForm.researchDir = val;
	    	if(val){
				vm.firstSubjectList[vm.zindex].levelTwo='';	    				
				vm.firstSubjectList[vm.zindex].twoSubjectList=this.subject[val];
			}
	    	
	    },
	    //更新认证信息（回显）
	    changeScholr1(val){
	    	if(val){
				vm.firstSubjectList[vm.zindex].levelThree='';               				
				vm.firstSubjectList[vm.zindex].threeSubjectList=this.subject[val];
           }
	    },
		//表单验证
		indexApplyNextVisible(ruleApply){
	    	this.$refs[ruleApply].validate((valid) => {
	          if (valid) {
	          	$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/user/getUserInfo",
					cache: false,
					crossDomain:true,
					dataType: 'json',
					headers:{"Authorization":this.token},
					success:(res) => {
						if(res.resultCode == 200){
							this.userId = res.content.result.id;
						}
					}
				});	            
				
				//学科
		    	for(var p in this.subject){
		    		this.subject[p].forEach(item=>{
		    			this.firstSubjectList.forEach(sub=>{
		    				if(sub.levelOne == item.id){
		    					sub.levelOneName = item.subjectName;
		    				}
		    				if(sub.levelTwo == item.id){
		    					sub.levelTwoName = item.subjectName;
		    				}
		    				if(sub.levelThree != ''){
		    					if(sub.levelThree == item.id){
		    						sub.levelThreeName = item.subjectName;
		    					}
		    				}
		    			})
		    		})
		    	}
	    	
				var subjectIdsArr = [];
				var subjectIds = '';
				this.subjectList.forEach(item=>{
					subjectIdsArr.push(item.levelTwo)
				})
				subjectIds = subjectIdsArr.join(',');
				//获取类型
				if(this.type == null){
					this.type = 1;
				}
			
				var authorunit = [];
				var authorUnitStr = '';
				this.applyForm.authorUnitArr.forEach(item=>{
					if (item.author || item.unit) {
						authorunit.push(item.author+"-"+item.unit)
					}else{
						authorUnitStr = this.applyForm.otherEditor;
					}
				})
				if (authorunit.length) {
					authorUnitStr = authorunit.join(',');
				}
			
				//获取关键词
				var keyWordArr = [];
				var keyWordStr = '';
				keyWordArr.push(this.applyForm.paperKeyWord);
				if(this.applyForm.paperKeyWord1 != ''){
					keyWordArr.push(this.applyForm.paperKeyWord1);
					if(this.applyForm.paperKeyWord2 != ''){
						keyWordArr.push(this.applyForm.paperKeyWord2);
						if(this.applyForm.paperKeyWord3 != ''){
							keyWordArr.push(this.applyForm.paperKeyWord3);
						}
					}
				}			
				keyWordStr = keyWordArr.join(',');
				var academic = {
					    "id": this.message,
					    "userId":this.userId,
					    "title":this.applyForm.paperTitle,
					    "type": this.type,
					    "typeStr": this.type,
					    "articleAttachment": this.articleAttachment,
					    "articleAttachmentId": this.articleAttachmentId,
						"authorFirst":this.applyForm.firstAuthor,
						"authorOthers":this.applyForm.paperOther,
					    "uploder": "",
					    "authorComm":this.applyForm.paperTelAuthor,
						"artSummary":this.applyForm.paperzhaiyao,
					    "keywords": keyWordStr,
					    "cnPicNo": this.applyForm.otherBookStyle,
					    "publishTime": "",
					    "fundName":this.applyForm.paperProject,
					    "sciencePublish": this.applyForm.otherBookPublication,
					    "status": this.status,
					    "statusStr": "",
					    "sort": 0,
					    "sendNo": 0,
					    "thesis":[],
					    "flag": this.flag,
					    "appraisalTotal": 0,
					    "tempFileId": [0],
					    "academicSubjects":this.firstSubjectList,
					    "authorUnit": authorUnitStr,
					    "certificateNo":this.applyForm.otherCertificateNum,
					    "copyrightNo": this.applyForm.otherCopyright,
					    "bookClassify": this.applyForm.bookstyle,
						"bookCount": this.applyForm.bookNumber,
					    "bookPubTime": this.applyForm.publishTime?this.applyForm.publishTime+' 00:00:00':this.applyForm.publishTime,
				}
				$.ajax({
					type: "post",
					url: ajaxUrl + "/txpy-web/academic/update",
					cache: false,
					crossDomain:true,
					dataType: 'json',
					contentType:'application/json;charset=UTF-8',
					data:JSON.stringify(academic),
					headers:{"Authorization":this.token},
					success:(res) => {
						console.log(res)
						if(res.resultCode == '0'){
							this.$message({
					            message: '提交成功！',
					            type: 'success'
					       });
							setTimeout(()=>{
								window.location.href = `/personal_center/expert_achievements.html?token=${this.token}`;									
			        		},1000)								
						}
					}
				});
	        }
	      });
          this.getSubjectList();
	    },
	    //上传功能
	    handleAvatarSuccess(res, file) {
	    	var res = res.content;
	    	for(var p in res){
	    		vm.articleAttachmentId = res[p].id;
	    	}
	    	this.imageUrl = URL.createObjectURL(file.raw);
	    },
	    beforeAvatarUpload(file) {
	        const isJPG = file.type === 'image/jpeg';
	        const isLt2M = file.size / 1024 / 1024 < 2;
	
	        if (!isJPG) {
	          this.$message.error('上传头像图片只能是 JPG 格式!');
	        }
	        if (!isLt2M) {
	          this.$message.error('上传头像图片大小不能超过 2MB!');
	        }
	        return isJPG && isLt2M;
	    },
	    handlePreview(file) {
	        console.log(file);
	    },
	    	//上传成功
	    handleSuc(res){
	    	var res = res.content;
	    	if(this.type == null){
				this.type = 1;
			}
	    	for(var p in res){
	    		this.thesis.push({
	    			'userId':this.userId,
			    	'name':res[p].name,
			    	'path':res[p].path,
			    	'id':res[p].id,
			    	"id": 0,
			    	"type":this.type,
	    		})
	    	}
	    },
	    handleRemove(file, fileList) {
	        console.log(file, fileList);
	    },
	    handlePreview(file) {
	        console.log(file);
	    },
	    handleExceed(files, fileList) {
	        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
	    },
	    beforeRemove(file, fileList) {
	        return this.$confirm(`确定移除 ${ file.name }？`);
	    },
	    //上传结束
	    //获取学科
	    getSubjectList(){
	    	$.ajax({
				type: "get",
				url: ajaxUrl + "/txpy-web/subject/list",
				cache: false,
				crossDomain:true,
				dataType: 'json',
				contentType:'application/json;charset=UTF-8',				
				headers:{"Authorization":this.token},
				data:{prevId:this.prevId},
				success:(res) => {
					if(res.resultCode == '0'){
						if(this.prevId == 0){
							this.oneSubjectList = res.content.list;
						}else{
							this.twoSubjectList = res.content.list;
						}
						
					}
				}
			});
	    },
	    changeOne(val){	    	
	    	this.prevId = val;
	    	this.ruleForm.levelId = val;
	    	this.getSubjectList();
	    	this.subjectList.levelTwo = '';	    			
	    	vm.subjectList[vm.zindex].twoSubjectList=this.subject[val];
	    },
	    addSubject(){
	    	var data={
	    			custom:"",
  					levelOne:null,
  					levelTwo:null,
  					levelThree:null,
  					isCustom:false,
		            listTwo:[],
		            listThree:[],
	    	};
	    	this.subjectList.push(data);
	    },
	    addSchor(){
	    	var data={
	    			custom:"",
  					levelOne:null,
  					levelTwo:null,
  					levelThree:null,
  					levelOneName :null,
  					levelTwoName:null,
  					levelThreeName:null,
  					isCustom:false,
		            listTwo:[],
		            listThree:[],
	    	};
	    	this.firstSubjectList.push(data);
	    },
	    delSubject(index){
	    	this.subjectList.splice(index,1);
	    },
	    delfirstSubject(index){
	    	this.firstSubjectList.splice(index,1);
	    },
	    delAuthorUnitArr(index){
	    	this.applyForm.authorUnitArr.splice(index,1);
	    },
	    addAuthorUnit(){
	    	var data ={
				author:null,
				unit:null,
			};
			this.applyForm.authorUnitArr.push(data);
	    },
	    //获取学科树状图
	    getsubject(){
			$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/subject/treeList",
					cache: false,
					crossDomain:true,
					headers:{"Authorization":this.token},
					data:{
						prevId:this.prevId
					},
					async: true,
					dataType: 'json',
					success:(res)=>{
						if (res.resultCode == "0") {  
						   this.subject = res.content.list;						   
	                    }   
					}
				})
            },
	    changeDict(val){
	    	this.dictName = val;
	    	this.ruleForm.dictTitle = val;
	    },
	    //日期改变时
	    changeDate(val){
	    	this.applyForm.publishTime = val;
	    },
	    //获取学术成果详细信息
	    getAceInfo(){
	    	$.ajax({
				type: "get",
				url: ajaxUrl + "/txpy-web/academic/getbyid",
				cache: false,
				crossDomain:true,
				headers:{"Authorization":this.token},
				data:{
					id:this.message
				},
				async: true,
				dataType: 'json',
				success:(res)=>{
					if (res.resultCode == "0") { 
						this.academic = res.content.AcademicAchievement.academic;
						this.articleAttachmentId = this.academic.articleAttachmentId;
						this.articleAttachment = this.academic.articleAttachment;
						this.status = this.academic.status;
						this.flag = this.academic.flag;
						var arr = this.academic.academicSubjects.slice(1, this.academic.academicSubjects.length);                //研究方向
					arr.forEach(item => {
						vm.addSchor();
					})
					this.academic.academicSubjects.forEach((item,index)=>{
						this.applyForm.researchDir = item.levelOne;
						vm.firstSubjectList[index].custom = item.custom;
						vm.firstSubjectList[index].levelOne = item.levelOne;
						vm.firstSubjectList[index].levelTwo = item.levelTwo;
						vm.firstSubjectList[index].levelThree = item.levelThree;
						if(item.levelOne){
							vm.firstSubjectList[index].twoSubjectList=this.subject[item.levelOne];							
						}
						if(item.levelTwo){
                            vm.firstSubjectList[index].threeSubjectList=this.subject[item.levelTwo];
                        }

					})

                        this.applyForm.styles = this.academic.type;
                        this.type = this.academic.type;
                        this.applyForm.paperTitle = this.academic.title;
					    if(this.academic.keywords.indexOf(',') != -1){
							this.keyWordArr=this.academic.keywords.split(',');
							this.applyForm.paperKeyWord = this.keyWordArr[0];	
							this.applyForm.paperKeyWord1 = this.keyWordArr[1];	
							this.applyForm.paperKeyWord2 = this.keyWordArr[2];	
							this.applyForm.paperKeyWord3 = this.keyWordArr[3];	
						}else{
							this.applyForm.paperKeyWord = this.academic.keywords;
						}
						this.applyForm.paperProject = this.academic.fundName;
						this.applyForm.firstAuthor = this.academic.authorFirst;
						this.applyForm.otherBookStyle = this.academic.cnPicNo;
						this.applyForm.paperTelAuthor = this.academic.authorComm;
						this.applyForm.paperzhaiyao = this.academic.artSummary;
						this.applyForm.otherBookPublication = this.academic.sciencePublish;
						this.applyForm.otherCertificateNum = this.academic.certificateNo;         //收录证书编号
						this.applyForm.otherCopyright = this.academic.copyrightNo;          //版权证书编号
						this.applyForm.bookstyle = this.academic.bookClassify;
						this.applyForm.bookNumber = this.academic.bookCount;
						this.applyForm.publishTime = this.academic.bookPubTime;
						this.applyForm.paperOther = this.academic.authorOthers;
						var unitAuthor =[];
						var arr = [];
						if(this.academic.authorUnit.indexOf("-") != -1){
							unitAuthor = this.academic.authorUnit.split(',');
							let unitAuthorList = unitAuthor.slice(1, unitAuthor.length);//职称
							unitAuthorList.forEach(item => {
								vm.addAuthorUnit();
							})							
							unitAuthor.forEach((item,index)=>{
								vm.applyForm.authorUnitArr[index].author=item.split('-')[0];
								vm.applyForm.authorUnitArr[index].unit=item.split('-')[1];
							})
						}else{
							this.applyForm.otherEditor = this.academic.authorUnit;                   //单位，作者							
						}						
                    }   
				}
			})
	    },
	      
	},
	mounted(){
		vm=this;
		this.getSubjectList();
		this.getsubject();
		this.getAceInfo();
	},
	created(){
		this.token  = getQueryVariable('token');
	}
	
}

</script>

<style scoped>

</style>