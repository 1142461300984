<template>
	<div>
		<el-form :model="form" :rules="rules" ref="ruleForm" style="text-align:left;">
			<el-form-item label="认证类型" :label-width="formLabelWidth" prop="styles" style="text-align:left;">
				<el-select v-model="form.styles" placeholder="学者认证" @change="styleChange()" :disabled="disabled">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="姓名" :label-width="formLabelWidth" prop="name" style="text-align:left;">
				<el-input v-model="form.name" autocomplete="off" style="width:350px;" placeholder="请输入姓名"></el-input>
			</el-form-item>
			<el-form-item label="性别" :label-width="formLabelWidth" prop="sex" style="text-align:left;">
				<el-select v-model="form.sex">
					<el-option label="男" value="1"></el-option>
					<el-option label="女" value="0"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="出生日期" :label-width="formLabelWidth" prop="birTime">
				<el-date-picker v-model="form.birTime" type="date" placeholder="选择日期" @change="dateChange" value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="民族" :label-width="formLabelWidth" prop="nation">
				<el-input v-model="form.nation" autocomplete="off" style="width:350px;"></el-input>
			</el-form-item>
			<el-form-item label="出生地" :label-width="formLabelWidth" prop="birthPlace">
				<el-input v-model="form.birthPlace" autocomplete="off" style="width:350px;"></el-input>
			</el-form-item>
			<el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
				<el-input v-model="form.email" autocomplete="off" style="width:350px;"></el-input>
			</el-form-item>
			<el-form-item label="单位" :label-width="formLabelWidth" prop="company">
				<el-input v-model="form.company" autocomplete="off" style="width:350px;"></el-input>
			</el-form-item>
			<el-form-item label="单位地址" :label-width="formLabelWidth" prop="companyAddress">
				<el-input v-model="form.companyAddress" autocomplete="off" style="width:350px;"></el-input>
			</el-form-item>

			<p style="height:1px;border:dashed 1px #ccc;"></p>

			<div v-if="form.styles=='学者认证'">
				<el-form-item label="学历" :label-width="formLabelWidth" style="margin-top:20px;">
					<el-select v-model="form.education">
						<el-option label="大学专科" value="大学专科"></el-option>
						<el-option label="大学本科" value="大学本科"></el-option>
						<el-option label="硕士研究生" value="硕士研究生"></el-option>
						<el-option label="博士" value="博士"></el-option>
						<el-option label="其他" value="其他"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="研究方向" :label-width="formLabelWidth">
					<div v-for="(sub,index) in form.subjectList" :key="index" style="margin-top:10px;">
						<el-select v-model="sub.levelOne" style="width:200px;" @focus="zindex=index" @change="changeScholr" placeholder="请选择">
							<el-option v-for="(item,index) in oneSubjectList" :label="item.subjectName" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="sub.levelTwo" @focus="zindex=index" @change="changeScholr1" style="width:200px;" placeholder="请选择">
							<el-option v-for="(item,index) in sub.twoSubjectList" :label="item.subjectName" :value="item.id">
							</el-option>
						</el-select>
						<el-select v-if="sub.threeSubjectList" v-model="sub.levelThree" style="width:200px;">
							<el-option v-for="(item,index) in sub.threeSubjectList" :label="item.subjectName" :value="item.id">
							</el-option>
						</el-select>
						<el-input v-if="!sub.threeSubjectList" v-model="sub.custom" autocomplete="off" style="width:200px;" placeholder="请填写"></el-input>
						<el-button v-if="form.subjectList.length>1" @click="delSubject(index)">删除</el-button>

					</div>

					<el-button @click="addScholr()" style="margin-top:10px;">+</el-button>
					<p style="font-size: 14px;color:red;">（如果您有更多研究方向，请点击“+”选择填写）</p>
				</el-form-item>

				<el-form-item label="职称" :label-width="formLabelWidth" inline="true">

					<div v-for="(sub,index) in form.totalDict" :key="index" style="margin-top:10px;">
						<el-select v-model="sub.dictOne" @focus="zindex=index" @change="dictScChange1" style="width:200px;">
							<el-option v-for="(item,index) in dictList" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="sub.dictTwo" style="width:200px;" @focus="zindex=index" @change="dictScChange2">
							<el-option label="初级" value="4"></el-option>
							<el-option label="中级" value="3"></el-option>
							<el-option label="副高级" value="2"></el-option>
							<el-option label="正高级" value="1"></el-option>
						</el-select>
						<el-select v-if="sub.dictScThree" v-model="sub.dictThree" style="width:200px;">
							<el-option v-for="(item,index) in sub.dictScThree" :label="item.titleName" :key="index" :value="item.id"></el-option>
						</el-select>
						<el-button v-if="form.totalDict.length>1" @click="delDict(index)">删除</el-button>
					</div>

					<el-button style="margin-top:10px;" @click="addDict()">+</el-button>
					<p style="font-size: 14px;color:red;">（如果您有更多的职称，请点击“+”选择填写）</p>
				</el-form-item>

				<el-form-item label="资质证书" :label-width="formLabelWidth">
					<ul class="l el-upload-list el-upload-list--picture-card">
						<li v-for='(item,index) in fileList1' class="el-upload-list__item is-success">
							<img class="el-upload-list__item-thumbnail" :src="item.url" />
							<span class="el-upload-list__item-actions">
                <span @click="handlePictureCardPreview(item)"
                      class="el-upload-list__item-preview">
                  <i class="el-icon-zoom-in"></i>
                </span>
							<span @click="handleRemove2(item,index)" class="el-upload-list__item-delete">
                  <i class="el-icon-delete"></i>
                </span>
							</span>
						</li>
					</ul>
					<el-upload :action="uploadUrl" list-type="picture-card" :on-success="handleSucc" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileList1" :show-file-list="false" accept=".jpg">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<p style="color:red;font-size:14px;margin-top:10px;text-align: left;">请上传正面清晰、字迹清楚的证书图片，支持jpg格式，且文件不超过1M</p>
				</el-form-item>
			</div>

			<div v-if="form.styles=='专家认证'">
				<el-form-item label="学历" :label-width="formLabelWidth" style="margin-top:20px;" prop="education">
					<el-select v-model="form.education">
						<el-option label="大学专科" value="大学专科"></el-option>
						<el-option label="大学本科" value="大学本科"></el-option>
						<el-option label="硕士研究生" value="硕士研究生"></el-option>
						<el-option label="博士" value="博士"></el-option>
						<el-option label="其他" value="其他"></el-option>
					</el-select>
				</el-form-item>
				<div class="">
					<div style="width: 132px;">
						<p style="margin-bottom: -26px;"><i style="color: #f56c6c;margin-right: 4px;">*</i>研究方向</p>
					</div>
					<div v-for="(sub,index) in form.subjectList" :key="index" class="achieveC" style="margin-left: 110px;">
						<el-form-item label="" :prop="`subjectList.${index}.levelOne`" :rules="subjectRules.levelOne" :label-width="formLabelWidth" style="float:left">
							<el-select v-model="sub.levelOne" style="width:200px;" @focus="zindex=index" @change="changeScholr" placeholder="请选择">
								<el-option v-for="(item,index) in oneSubjectList" :label="item.subjectName" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" :prop="`subjectList.${index}.levelTwo`" :rules="subjectRules.levelTwo" :label-width="formLabelWidth" style="float:left">
							<el-select v-model="sub.levelTwo" placeholder="" @focus="zindex=index" @change="changeScholr1" style="width:200px;" placeholder="请选择">
								<el-option v-for="(item,index) in sub.twoSubjectList" :label="item.subjectName" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" :prop="`subjectList.${index}.levelThree`" :rules="subjectRules.levelThree" :label-width="formLabelWidth" style="float:left">
							<el-select v-if="sub.threeSubjectList" v-model="sub.levelThree" style="width:200px;">
								<el-option v-for="(item,index) in sub.threeSubjectList" :label="item.subjectName" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" :prop="`subjectList.${index}.custom`" :rules="subjectRules.custom">
							<el-input v-if="!sub.threeSubjectList" v-model="sub.custom" autocomplete="off" style="width:200px;" placeholder="请填写"></el-input>
							<el-button v-if="form.subjectList.length>1" @click="delSubject(index)">删除</el-button>
						</el-form-item>
					</div>
					<el-button @click="addScholr" style="margin-top: -10px;margin-left: 120px;">+</el-button>
					<p style="font-size: 14px;color:red;margin-left: 116px;margin-top: 10px;margin-bottom: 20px;">
						（如果您有更多研究方向，请点击“+”选择填写）</p>
				</div>

				<div class="">
					<div style="width: 132px;">
						<p style="margin-bottom: -26px;"><i style="color: #f56c6c;margin-right: 4px;">*</i>职称</p>
					</div>
					<div v-for="(sub,index) in form.totalDict" :key="index" class="achieveC" style="margin-left: 110px;">
						<el-form-item label="" :prop="`totalDict.${index}.dictOne`" :rules="totalDictRules.dictOne" :label-width="formLabelWidth" style="float:left">
							<el-select v-model="sub.dictOne" @focus="zindex=index" @change="dictScChange1" style="width:200px;">
								<el-option v-for="(item,index) in dictList" :label="item.name" :value="item.id" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" :prop="`totalDict.${index}.dictTwo`" :rules="totalDictRules.dictTwo" :label-width="formLabelWidth" style="float:left">
							<el-select v-model="sub.dictTwo" style="width:200px;" @focus="zindex=index" @change="dictScChange2">
								<el-option label="初级" value="4"></el-option>
								<el-option label="中级" value="3"></el-option>
								<el-option label="副高级" value="2"></el-option>
								<el-option label="正高级" value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="" :prop="`totalDict.${index}.dictThree`" :rules="totalDictRules.dictThree" :label-width="formLabelWidth" style="float:left">
							<el-select v-model="sub.dictThree" style="width:200px;">
								<el-option v-for="(item,index) in sub.dictScThree" :label="item.titleName" :value="item.id" :key="index"></el-option>
							</el-select>
							<el-button v-if="form.totalDict.length>1" @click="delDict(index)">删除</el-button>
						</el-form-item>
					</div>
					<el-button @click="addDict" style="margin-top: -10px;margin-left: 120px;">+</el-button>
					<p style="font-size: 14px;color:red;margin-left: 116px;margin-top: 10px;margin-bottom: 20px;">
						如果您有更多的职称，请点击“+”选择填写）</p>
				</div>

				<div style="position:relative;">
					<div style="width: 132px;">
						<p style="margin-bottom: -26px;"><i style="color: #f56c6c;margin-right: 4px;">*</i>资质证书</p>
					</div>
					<el-form-item label="" :label-width="formLabelWidth">
						<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" accept=".jpg" :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" style="float: left;">
							<img v-if="imageUrl1" :src="imageUrl1" class="avatar" style="width:140px;height:140px;">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 16px;line-height: 140px;">学位证书</i>
						</el-upload>
						<el-upload class="avatar-uploader" accept=".jpg" :action="uploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload" style="float: left;margin-left:20px;">
							<img v-if="imageUrl2" :src="imageUrl2" class="avatar" style="width:140px;height:140px;">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 16px;line-height: 140px;">学历证书</i>
						</el-upload>
						<el-upload class="avatar-uploader" accept=".jpg" :action="uploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess3" :before-upload="beforeAvatarUpload" style="float: left;margin-left:20px;">
							<img v-if="imageUrl3" :src="imageUrl3" class="avatar" style="width:140px;height:140px;">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 16px;line-height: 140px;">职称证书</i>
						</el-upload>
						<el-upload class="avatar-uploader" accept=".jpg" :action="uploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess4" :before-upload="beforeAvatarUpload" style="float: left;margin-left:20px;">
							<img v-if="imageUrl4" :src="imageUrl4" class="avatar" style="width:140px;height:140px;">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 16px;line-height: 140px;">职业技能证书</i>
						</el-upload>
						<ul class="l el-upload-list el-upload-list--picture-card">
							<li v-for='(item,index) in fileList1' class="el-upload-list__item is-success" :key="index">
								<img class="el-upload-list__item-thumbnail" :src="item.url" />
								<span class="el-upload-list__item-actions">
                  <span @click="handlePictureCardPreview(item)"
                        class="el-upload-list__item-preview">
                    <i class="el-icon-zoom-in"></i>
                  </span>
								<span @click="handleRemove2(item,index)" class="el-upload-list__item-delete">
                    <i class="el-icon-delete"></i>
                  </span>
								</span>
							</li>
						</ul>
						<el-upload :action="uploadUrl" accept=".jpg" list-type="picture-card" :on-success="handleSucc" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileList1" :show-file-list="false">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
						<p style="color:red;font-size:14px;margin-top:10px;text-align: left;">请上传正面清晰、字迹清楚的证书图片，支持jpg格式，且文件不超过1M</p>
					</el-form-item>
				</div>
			</div>
		</el-form>
		<div slot="footer" class="dialog-footer" style="text-align:center;">
			<el-button type="primary" @click="submitForm('ruleForm')" style="width:120px;">下一步</el-button>
		</div>

		<el-dialog :visible.sync="centerDialogVisibles" :append-to-body='true' @close='callback' width="37%">
			<div class="zhezhao" style="width: 90%;height: 65px;position: absolute;opacity: 0;display: none;"></div>
			<el-upload class="upload-demo" :action="uploadUrl" :on-preview="handlePreview" :on-exceed="handleExceed" :file-list="fileList" :show-file-list="false" accept=".pdf,.mp4,.avi,.doc,.docx" :on-success="handleSuc" :on-progress="progress" :on-error="handleError" :before-upload="beforeAvatarUpload1">
				<div class="" style="text-align: left;">
					<span style="font-size:16px;font-weight: bolder;"><i v-if="star"
               style="color: red;">*</i>上传成果</span>
					<el-button size="small" type="primary" style="margin-left: 30px;">点击上传</el-button>
				</div>
				<p style="color:red;font-size:14px;margin-top:10px;text-align: left;">请上传您具有代表性的学术成果，认证时上传的学术成果仅作为后台审核用途</p>
				<p style="color:red;font-size:14px;margin-top:10px;text-align: left;">支持文件格式：doc、docx、pdf，且文件不超过15M;mp4、avi,且文件不超过100M</p>
				<p style="color:red;font-size:14px;margin-top:10px;text-align: left;">支持单个文件上传，最大上传次数不超过10次</p>
			</el-upload>
			<ul class="el-upload-list el-upload-list--text">
				<li tabindex="0" class="el-upload-list__item is-success" v-if="tempAchievementList.length" v-for="(item,index) in tempAchievementList" :key="index">
					<a class="el-upload-list__item-name">
						<i class="el-icon-document"></i> {{item.name}}
					</a>
					<label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-circle-check"></i>
          </label>
					<i class="el-icon-close" @click="handleRemoveS(item.id,index)"></i>
					<i class="el-icon-close-tip">按 delete 键可删除</i>
				</li>
				<li tabindex="0" class="el-upload-list__item is-success" v-if="imgFlag==true">
					<a class="el-upload-list__item-name">
						<i class="el-icon-document"></i> {{fileName}}
					</a>
					<el-progress :percentage="percent" style="margin-top: 4px;"></el-progress>
				</li>
			</ul>
			<div slot="footer" class="dialog-footer" style="text-align:center;">
				<el-button type="primary" v-if="imgFlag==false" @click="uploadClick()">提交</el-button>
				<!--centerDialogVisibles = false-->
			</div>
		</el-dialog>

		<!--专家认证信息弹框结束-->
	</div>
</template>
<script>
	var vm;
	export default {
		props: ['message'],
		data: function() {
			return {
				uploadUrl: null,
				subject: [],
				percent: 0,
				imgFlag: false,
				fileName: '',
				isLt100M: '',
				isLt15M: '',
				zindex: null,
				star: false,
				zclist: ['senior', 'subSenior', 'middle', 'elementary'],
				fileList: [],
				fileList1: [],
				fileList01: [],
				delCertificateIds: [], //删除资质证书
				delAchievementIds: [], //删除学术成果
				delCertificateIds1: [],
				delCertificateIds2: [],
				tempAchievementList: [],
				publicUrl: publicUrl,
				commonUrl: commonUrl,
				token: '',
				certificatesList: [],
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				tempAchievementIds: [],
				tempCertificateIds: [], //图片上传id
				identificationId: null, //认证id
				id: null, //修改id
				userId: null,
				dictList: [],
				oneSubjectList: [],
				twoSubjectList: [],
				identificationType: '',
				twoDictList: [],
				twoDictStr: '',
				dictSc1: '',
				dictScThree: [],
				limit: 10,
				prevId: 0,
				token: '',
				active: 0,
				publicUrl: publicUrl,
				commonUrl: commonUrl,
				ajaxUrl: ajaxUrl,
				centerDialogVisibles: false,
				value1: '',
				imageUrl: '',
				imageUrl1: '',
				imageUrl2: '',
				imageUrl3: '',
				imageUrl4: '',
				disabled: false,
				form: {
					name: '',
					styles: '学者认证',
					sex: '',
					birTime: '',
					nation: '',
					birthPlace: '',
					email: '',
					education: '',
					company: '',
					companyAddress: '',
					scholrSubject: '',
					dict: '',
					dict2: '',
					subjectList: [{
						custom: "",
						levelOne: null,
						levelTwo: null,
						levelThree: null,
						isCustom: false,
						listTwo: [],
						listThree: [],
					}],
					totalDict: [{ //职称
						dictOne: null,
						dictTwo: null,
						dictThree: null,
					}],
				},
				options: [{
					value: '学者认证',
					label: '学者认证'
				}, {
					value: '专家认证',
					label: '专家认证'
				}],
				subjectRules: {
					levelOne: [{
						required: true,
						message: '研究方向不能为空',
						trigger: 'blur'
					}, ],
					levelTwo: [{
						required: true,
						message: '研究方向不能为空',
						trigger: 'blur'
					}, ],
				},
				totalDictRules: {
					dictOne: [{
						required: true,
						message: '职称不能为空',
						trigger: 'blur'
					}, ],
					dictTwo: [{
						required: true,
						message: '职称不能为空',
						trigger: 'blur'
					}, ],
					dictThree: [{
						required: true,
						message: '职称不能为空',
						trigger: 'blur'
					}, ],
				},
				rules: {
					name: [{
						required: true,
						message: '姓名不能为空',
						trigger: 'blur'
					}, ],

					birTime: [{
						required: true,
						message: '出生日期不能为空',
						trigger: 'blur'
					}, ],
					styles: [{
						required: true,
						message: '认证类型不能为空',
						trigger: 'blur'
					}, ],
					sex: [{
						required: true,
						message: '性别不能为空',
						trigger: 'blur'
					}, ],
					nation: [{
						required: true,
						message: '民族不能为空',
						trigger: 'blur'
					}, ],
					birthPlace: [{
						required: true,
						message: '出生地不能为空',
						trigger: 'blur'
					}, ],
					email: [{
							required: true,
							message: '请输入邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请输入正确的邮箱地址',
							trigger: 'blur'
						}
					],
					company: [{
						required: true,
						message: '公司名称不能为空',
						trigger: 'blur'
					}, ],
					companyAddress: [{
						required: true,
						message: '公司地址不能为空',
						trigger: 'blur'
					}, ],
					education: [{
						required: true,
						message: '学历不能为空',
						trigger: 'blur'
					}, ],
					dictOne: [{
						required: true,
						message: '职称不能为空',
						trigger: 'blur'
					}, ],
				},
				formLabelWidth: '120px',
				sysUserAchievement: [],
			}
		},
		mounted() {
			vm = this;
			this.getsubject();
			this.identificationType = JSON.parse(sessionStorage.getItem("userInfo")).identificationType;
			this.presentStatus = JSON.parse(sessionStorage.getItem("userInfo")).presentStatus;
			this.userId = JSON.parse(sessionStorage.getItem("userInfo")).userId;
			this.fdsfPrefixPath = JSON.parse(sessionStorage.getItem("userInfo")).fdsfPrefixPath;
			this.uploadUrl = ajaxUrl + `/txpy-web/test/common/upload?token=${this.token}`;
			this.getSubjectList();
			this.getDictList();
			this.IdentificationInfo();
			setTimeout(function() {
				vm.manifest();
			}, 1000)
		},
		created() {

			this.token = getQueryVariable('token');
		},
		methods: {
			//出生日期
			dateChange(val) {
				this.form.birTime = val;
			},

			changeScholr(val) {
				this.prevId = val;
				if(val) {
					vm.form.subjectList[vm.zindex].levelTwo = '';
					vm.form.subjectList[vm.zindex].twoSubjectList = this.subject[val];
				}
			},
			changeScholr1(val) {
				if(val) {
					vm.form.subjectList[vm.zindex].levelThree = '';
					vm.form.subjectList[vm.zindex].threeSubjectList = this.subject[val];
				}
			},

			//职称1
			dictScChange1(val) {
				this.dictSc1 = val;
				this.dictScThree = [];
				let dictList = vm.dictList[val];
				let zc = vm.zclist[val - 1];
				vm.form.totalDict[vm.zindex].dictThree = '';
				vm.form.totalDict[vm.zindex].dictTwo = '';
			},
			//职称2
			dictScChange2(val) {
				let index = vm.form.totalDict[vm.zindex].dictOne;
				vm.form.totalDict[vm.zindex].dictThree = '';
				let gradeName = vm.zclist[val - 1];
				let dictList = vm.mapJobList(index, gradeName);
				vm.form.totalDict[vm.zindex].dictScThree = dictList;
			},
			mapJobList(seriesId, gradeId) {
				let data = {}
				vm.dictList.forEach(item => {
					if(item.id == seriesId) {
						data = item[gradeId]
					}
				})
				return data
			},

			//更新认证信息（回显）
			manifest() {
				if(this.presentStatus == 1) {
					this.form.styles = '专家认证';
					this.disabled = true;
				}
				if(this.presentStatus == 2) {
					if(vm.message == 1) {
						this.form.styles = '专家认证';
						this.disabled = true;
					} else {
						this.form.styles = '学者认证';
						this.disabled = true;
					}
				}
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/user/getUserInfo",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						let data = res.content.result;
						this.certificatesList = data.userCertificates;
						this.form.name = data.nickname;
						this.form.sex = data.sysUserInfo.sex;
						this.form.birTime = data.sysUserInfo.birthday;
						this.form.nation = data.sysUserInfo.nation;
						this.form.birthPlace = data.sysUserInfo.birthPlace;
						this.form.email = data.email;
						this.form.company = data.sysUserInfo.unit;
						this.form.companyAddress = data.sysUserInfo.unitAddr;
						this.form.education = data.sysUserInfo.education;
						this.form.education = data.sysUserInfo.education;
						//研究方向
						let arr = data.sysUserSubjects.slice(1, data.sysUserSubjects.length);
						arr.forEach(item => {
							vm.addScholr();
						})
						data.sysUserSubjects.forEach((item, index) => {
							vm.form.subjectList[index].custom = item.custom;
							vm.form.subjectList[index].levelOne = item.levelOne;
							vm.form.subjectList[index].levelTwo = item.levelTwo;
							vm.form.subjectList[index].levelThree = item.levelThree;
							if(item.levelOne) {
								vm.form.subjectList[index].twoSubjectList = this.subject[item.levelOne];
							}
							if(item.levelTwo) {
								vm.form.subjectList[index].threeSubjectList = this.subject[item.levelTwo];
							}

						})
						//职称
						if(data.dictTechTitles) {
							let dictTechList = data.dictTechTitles.slice(1, data.dictTechTitles.length);
							dictTechList.forEach(item => {
								vm.addDict();
							})
							data.dictTechTitles.forEach((item, index) => {
								vm.form.totalDict[index].dictOne = item.seriesId;
								vm.form.totalDict[index].dictTwo = String(item.gradeId);
								vm.form.totalDict[index].dictThree = item.id;
								if(item.id) {
									let gradeName = vm.zclist[item.gradeId - 1];
									let dictList = vm.mapJobList(item.seriesId, gradeName);
									vm.form.totalDict[index].dictScThree = dictList;
								}
							})
						}

						//资质证书
						if(this.identificationType == 1 || (this.identificationType == 2 && this.message == 1)) { //专家
							for(var i = 0; i < data.userCertificates.length; i++) {
								if(data.userCertificates[0]) {
									vm.imageUrl1 = vm.fdsfPrefixPath + data.userCertificates[0].context;
								}
								if(data.userCertificates[1]) {
									vm.imageUrl2 = vm.fdsfPrefixPath + data.userCertificates[1].context;
								}
								if(data.userCertificates[2]) {
									vm.imageUrl3 = vm.fdsfPrefixPath + data.userCertificates[2].context;
								}
								if(data.userCertificates[3]) {
									vm.imageUrl4 = vm.fdsfPrefixPath + data.userCertificates[3].context;
								}
								vm.delCertificateIds1.push(data.userCertificates[i].id);
							}
							if(data.userCertificates[4]) {
								let userCertificatesList = data.userCertificates.slice(4, data.userCertificates.length);
								userCertificatesList.forEach(item => {
									vm.fileList1.push({
										url: vm.fdsfPrefixPath + item.context,
										createTime: item.createTime,
										context: item.context,
										updateTime: item.updateTime,
										id: item.id,
										title: item.title,
										userId: item.userId,
									})
									vm.delCertificateIds2.push(item.id);
								})
							}

							this.fileList01 = data.userCertificates;
						}
						if(this.identificationType == 2 && (this.message != 1)) { //学者
							data.userCertificates.forEach((item, index) => {
								vm.fileList1.push({
									url: vm.fdsfPrefixPath + item.context,
									createTime: item.createTime,
									context: item.context,
									updateTime: item.updateTime,
									id: item.id,
									title: item.title,
									userId: item.userId,
								})
								vm.delCertificateIds2.push(item.id);
							})

						}

						//上传成果
						this.sysUserAchievement = data.sysUserAchievement;
						data.sysUserAchievement.forEach(item => {
							if(vm.tempAchievementList.length < 10) {
								vm.tempAchievementList.push({
									'userId': this.userId,
									'name': item.name,
									'path': item.path,
									'id': item.id,
									'size': item.size,
									'createTime': item.createTime,
								})
							}
						})
					},
				});
			},

			//提交表单
			uploadClick() {
				let becomeExperts = '0';
				let fileListC = [];
				let list = [];
				fileListC = this.fileList1;
				this.fileList01.forEach(item => {
					list.push(item.id)
				})
				if(vm.message == 1 && vm.identificationType == 2) {
					becomeExperts = 1;
					if(!this.tempAchievementList.length) {
						this.$message({
							message: '请上传成果',
							type: 'warning'
						});
						return;
					}
				}
				if(vm.message == 2) {
					becomeExperts = 2;
				}
				if(vm.message == 1) {
					var type = vm.identificationType;
					fileListC = this.fileList01;
				} else {
					if(this.form.styles == '专家认证') {
						var type = 1;
						fileListC = this.fileList01;
						if(!this.tempAchievementList.length) {
							this.$message({
								message: '请上传成果',
								type: 'warning'
							});
							return;
						}
					} else {
						var type = 2;
					}
				}
				this.form.totalDict.forEach(item => {
					this.twoDictList.push(item.dictThree);
					this.twoDictStr = this.twoDictList.join(',');
				})
				var userIdentificationDto = {
					"sysUserAchievementArray": this.tempAchievementList, //上传成果的信息
					"sysUserCertificateArray": fileListC,
					"identificationAuditStatus": becomeExperts,
					"sysUserSubjectArray": this.form.subjectList,
					"sysUserTitleArray": this.form.totalDict,
					"tempAchievementIds": this.tempAchievementIds, //上传成果的id
					"tempCertificateIds": this.tempCertificateIds, //资质证书
					"delCertificateIds": this.delCertificateIds,
					"delAchievementIds": this.delAchievementIds, //删除的上传成果id
					"userIdentification": {
						"id": this.identificationId,
						"type": type,
						"userId": this.userId
					},
					"userInfoDto": {
						"id": this.id,
						"age": '',
						"addr": '',
						"userId": this.userId,
						"city": "",
						"province": "",
						"unit": this.form.company,
						"unitAddr": this.form.companyAddress,
						"qq": "",
						"sex": this.form.sex,
						"birthday": this.form.birTime,
						"nation": this.form.nation,
						"birthPlace": this.form.birthPlace,
						"education": this.form.education,
						"tel": "",
						"mailing": "",
						"mailNum": "",
						"department": "",
						"title": this.twoDictStr,
						"researchField": "",
						"hideStatus": 0
					},
					'nickname': this.form.name,
					'email': this.form.email
				}
				userIdentificationDto.userInfoDto = vm.filterSpace(userIdentificationDto.userInfoDto)
				// console.log(userIdentificationDto.userInfoDto)
				// return

				$.ajax({
					type: "post",
					url: ajaxUrl + "/txpy-web/user/identification",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					async: true,
					dataType: 'json',
					contentType: 'application/json;charset=UTF-8',
					data: JSON.stringify(userIdentificationDto),
					success: (res) => {
						if(res.resultCode == 200) {
							this.$message({
								message: '认证提交成功！',
								type: 'success'
							});
							setTimeout(function() {
								window.location.href = `/personal_center/expert_management.html?token=${vm.token}`;
								this.centerDialogVisibles = false;
							}, 2000)
						}
					},
					error: (res) => {
						this.$message({
							message: res.responseJSON.resultDesc,
							type: 'warning'
						});
						setTimeout(function() {
							window.location.href = `/personal_center/expert_management.html?token=${vm.token}`;
							this.centerDialogVisibles = false;
						}, 2000)
					}
				});
			},
			filterSpace(data) {
				for(let key in data) {
					if(!data[key]) {
						data[key] = null
					}
				}
				return data
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			showActive(index) {
				this.active = index;
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},

			//上传成果
			handleRemoveS(id, index) {
				this.tempAchievementList.splice(index, 1);
				this.delAchievementIds.push(id);
				$('.zhezhao').hide();
			},

			progress(event, file, fileList) {
				this.fileName = file.name;
				let type = file.raw.type.split('/')[1];
				let size = file.size / 1024 / 1024;
				if(type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
					if(size < 15) {
						this.imgFlag = true;
						this.percent = Math.floor(event.percent);
					}
				}
				if(type == 'mp4' || type == 'avi') {
					if(size < 100) {
						this.imgFlag = true;
						this.percent = Math.floor(event.percent);
					}
				}
			},

			//资质证书
			handleRemove2(file, index) {
				this.fileList1.splice(index, 1);
				this.fileList01.splice(index, 1);
				vm.delCertificateIds.push(vm.delCertificateIds2[index]);
			},
			handleRemove(file) {},
			handlePreview(file) {},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 ${this.limit} 个文件!`);
			},
			//上传成功
			handleSuc(res) {
				if(res.resultCode == '0') {
					this.imgFlag = false;
					this.percent = 0;
					var res = res.content;
					for(var p in res) {
						this.tempAchievementList.push({
							'userId': vm.userId,
							'name': res[p].name,
							'path': res[p].path,
							'id': res[p].id,
							'size': res[p].size,
							'createTime': res[p].createTime,
						})
						this.tempAchievementIds.push(res[p].id)
					}
					//      this.$message({
					//        message: 'PDF上传成功！',
					//        type: 'success'
					//      });
				}

			},
			//上传成果失败
			handleError(file, fileList) {
				this.imgFlag = false;
				let type = fileList.raw.type.split('/')[1];
				let size = fileList.size / 1024 / 1024;
				if(type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
					if(size < 15) {
						this.$message({
							message: '上传成果失败！',
							type: 'warning'
						});
					}
				}
				if(type == 'mp4' || type == 'avi') {
					if(size < 100) {
						this.$message({
							message: '上传成果失败！',
							type: 'warning'
						});
					}
				}
			},

			//上传成果结束

			submitForm(ruleForm) {
				if(this.form.styles == '专家认证') {
					this.star = true;
				}
				if((vm.message == 1 && vm.identificationType == 2) || vm.identificationType == 1) {
					for(var i = 0; i < vm.form.subjectList.length; i++) {
						if(!vm.form.subjectList[i].threeSubjectList) {
							if(!vm.form.subjectList[i].custom) {
								this.$message({
									message: `请输入第${i + 1}个研究方向的三级研究方向！`,
									type: 'warning'
								});
								return;
							}
						} else {
							if(!vm.form.subjectList[i].levelThree) {
								this.$message({
									message: `请输入第${i + 1}个研究方向的三级研究方向！`,
									type: 'warning'
								});
								return;
							}
						}
					}
				}
				if(!this.certificatesList.length) {
					if(!this.tempCertificateIds.length) {
						if(vm.identificationType == 2 && vm.message == 1) {
							this.$message({
								message: '请上传资质证书！',
								type: 'warning'
							});
							return;
						}
						if(vm.identificationType == 1) {
							this.$message({
								message: '请上传资质证书！',
								type: 'warning'
							});
							return;
						}
					}
				}
				this.getUserInfo();
				this.$refs[ruleForm].validate((valid) => {
					if(valid) {
						this.centerDialogVisibles = true;
					} else {
						return false;
					}
				});

			},

			//弹窗关闭
			callback() {
				this.tempAchievementList = this.sysUserAchievement;
				$('.zhezhao').hide();
				this.imgFlag = false;
			},

			//上传资质证书
			handleSucc(res, file) {
				var res = res.content;

				for(var p in res) {
					this.fileList1.push({
						url: vm.fdsfPrefixPath + res[p].path,
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					})
					this.fileList01.push({
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					})
					this.tempCertificateIds.push(res[p].id)
				}

			},
			handleAvatarSuccess(res, file) {

			},
			handleAvatarSuccess1(res, file) {
				var res = res.content;
				for(var p in res) {
					this.tempCertificateIds.push(res[p].id);
					if(vm.delCertificateIds1[0]) {
						vm.delCertificateIds.push(vm.delCertificateIds1[0]);
					}

					let obj = {
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					};
					this.fileList01.splice(0, 1, obj);
				}
				this.imageUrl1 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess2(res, file) {
				var res = res.content;
				for(var p in res) {
					this.tempCertificateIds.push(res[p].id)
					if(vm.delCertificateIds1[1]) {
						vm.delCertificateIds.push(vm.delCertificateIds1[1]);
					}

					let obj = {
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					};
					this.fileList01.splice(1, 1, obj);

				}

				this.imageUrl2 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess3(res, file) {
				var res = res.content;
				for(var p in res) {
					this.tempCertificateIds.push(res[p].id)
					if(vm.delCertificateIds1[2]) {
						vm.delCertificateIds.push(vm.delCertificateIds1[2]);
					}

					let obj = {
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					};
					this.fileList01.splice(2, 1, obj);
				}

				this.imageUrl3 = URL.createObjectURL(file.raw);
			},
			handleAvatarSuccess4(res, file) {
				var res = res.content;
				for(var p in res) {
					this.tempCertificateIds.push(res[p].id)
					if(vm.delCertificateIds1[3]) {
						vm.delCertificateIds.push(vm.delCertificateIds1[3]);
					}

					let obj = {
						id: res[p].id,
						context: res[p].path,
						createTime: res[p].createTime,
						title: '',
						userId: vm.userId,
						updateTime: '',
					};
					this.fileList01.splice(3, 1, obj);
				}
				this.imageUrl4 = URL.createObjectURL(file.raw);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				//	        console.log(file);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if(!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if(!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},

			beforeAvatarUpload1(file) {
				const type = file.type.split('/')[1];
				this.isLt15M = file.size / 1024 / 1024 < 15;
				this.isLt100M = file.size / 1024 / 1024 < 100;
				if(this.tempAchievementList.length > 9) {
					$('.zhezhao').show();
					this.$message.warning(`当前选择文件已达到最大限度!`)
					return false;
				} else {
					$('.zhezhao').hide();
					if(type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
						if(!this.isLt15M) {
							this.$message.warning('上传文件大小不能超过 15MB!');
						}
					}
					if(type == 'mp4' || type == 'avi') {
						if(!this.isLt100M) {
							this.$message.warning('上传文件大小不能超过 100MB!');
						}
					}
					return this.isLt15M || this.isLt100M;
				}
			},
			//获取认证接口
			IdentificationInfo() {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/user/getUserIdentificationInfo",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						this.identificationId = res.content.result.identificationId;
					}
				})
			},
			//获取用户信息
			getUserInfo() {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/user/getUserInfo",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						this.userId = res.content.result.id;
						this.id = res.content.result.sysUserInfo.id;
						this.identificationType = res.content.result.identificationType;
					},
				});
			},
			styleChange() {
				this.form.totalDict = [{ //职称
					dictOne: null,
					dictTwo: null,
					dictThree: null,
				}]
				this.form.subjectList = [{
					custom: "",
					levelOne: null,
					levelTwo: null,
					levelThree: null,
					isCustom: false,
					listTwo: [],
					listThree: [],
				}];
				this.form.name = '';
				this.form.sex = '';
				this.form.birTime = '';
				this.form.nation = '';
				this.form.birthPlace = '';
				this.form.email = '';
				this.form.company = '';
				this.form.companyAddress = '';
				this.form.education = '';
			},
			getsubject() {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/subject/treeList",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					data: {
						prevId: this.prevId
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						if(res.resultCode == "0") {
							this.subject = res.content.list;
						}
					}
				})
			},
			//获取学科
			getSubjectList() {
				$.ajax({
					type: "get",
					url: ajaxUrl + "/txpy-web/subject/list",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					data: {
						prevId: this.prevId
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						if(this.prevId == 0) {
							this.oneSubjectList = res.content.list;
						} else {
							this.twoSubjectList = res.content.list;
						}

					}
				})
			},

			//获取职称
			getDictList() {
				$.ajax({
					type: "post",
					url: ajaxUrl + "/txpy-web/zw/title/list",
					cache: false,
					crossDomain: true,
					headers: {
						"Authorization": this.token
					},
					async: true,
					dataType: 'json',
					success: (res) => {
						this.dictList = res.content.list;
					}
				})
			},

			addScholr() {
				var data = {
					custom: "",
					levelOne: null,
					levelTwo: null,
					levelThree: null,
					isCustom: false,
					listTwo: [],
					listThree: [],
				}
				this.form.subjectList.push(data);
			},
			delSubject(index) {
				this.form.subjectList.splice(index, 1);
			},
			addDict() {
				var dic = {
					dictOne: null,
					dictTwo: null,
					dictThree: null,
				}
				this.form.totalDict.push(dic);
			},
			delDict(index) {
				this.form.totalDict.splice(index, 1);
			},
		},
	}
</script>

<style scoped>
	.el-upload {
		text-align: left;
	}
	
	.achieveC {
		width: 100%;
		float: left;
	}
	
	/deep/ .achieveC .el-form-item {
		float: left;
	}
</style>