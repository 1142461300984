<template>
  <div>
    <el-form :model="applyForm"
             :rules="applyRules"
             ref="ruleApply">
      <el-form-item label="类型"
                    :label-width="formLabelWidth"
                    prop="styles">
        <el-select v-model="applyForm.styles"
                   @change="changType"
                   style="width:300px">
          <el-option v-for="item in applyOptions"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"
                     :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="applyForm.styles == '1'">
        <el-form-item label="标题"
                      :label-width="formLabelWidth"
                      prop="paperTitle">
          <el-input v-model="applyForm.paperTitle"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="关键词"
                      :label-width="formLabelWidth"
                      prop="paperKeyWord">
          <el-input v-model="applyForm.paperKeyWord"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord1"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord2"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord3"
                    autocomplete="off"
                    style="width:100px;"></el-input>
        </el-form-item>
        <el-form-item label="基金项目"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.paperProject"
                    autocomplete="off"
                    style="width:350px;"></el-input>
        </el-form-item>
        <el-form-item label="第一作者"
                      :label-width="formLabelWidth"
                      prop="firstAuthor">
          <el-input v-model="applyForm.firstAuthor"
                    autocomplete="off"
                    style="width:350px;"></el-input>
        </el-form-item>
        <el-form-item label="其他作者"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.paperOther"
                    autocomplete="off"
                    style="width:350px;"></el-input>
          <p style="font-size: 14px;color: red;">（如有多个作者，请用逗号隔开）</p>
        </el-form-item>
        <el-form-item label="通讯作者"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.paperTelAuthor"
                    autocomplete="off"
                    style="width:350px;"></el-input>
        </el-form-item>
        <el-form-item label="摘要"
                      :label-width="formLabelWidth"
                      prop="paperzhaiyao">
          <el-input type="textarea"
                    v-model="applyForm.paperzhaiyao"
                    style="width:410px;"
                    maxlength="150"
                    :rows="5"></el-input>
        </el-form-item>
      </div>

      <div v-else-if="applyForm.styles == '2'">
        <el-form-item label="图书名称"
                      :label-width="formLabelWidth"
                      prop="paperTitle">
          <el-input v-model="applyForm.paperTitle"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入图书名称"></el-input>
        </el-form-item>
        <el-form-item label="图书分类"
                      :label-width="formLabelWidth"
                      prop="bookstyle">
          <el-input v-model="applyForm.bookstyle"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入图书类型"></el-input>
        </el-form-item>
        <el-form-item label="图书字数"
                      :label-width="formLabelWidth"
                      prop="bookNumber">
          <el-input type="number"
                    v-model="applyForm.bookNumber"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入图书字数"></el-input>
        </el-form-item>
        <el-form-item label="出版时间"
                      :label-width="formLabelWidth"
                      prop="publishTime">
          <el-date-picker v-model="applyForm.publishTime"
                          type="date"
                          placeholder="选择日期"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          @change="changeDate">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="第一主编"
                      :label-width="formLabelWidth"
                      prop="firstAuthor">
          <el-input v-model="applyForm.firstAuthor"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入第一主编姓名"></el-input>
        </el-form-item>
        <el-form-item label="第二主编"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.paperOther"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入第二主编姓名"></el-input>
        </el-form-item>
        <el-form-item label="其他主编"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.otherEditor"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入其他主编姓名"></el-input>
          <p style="font-size: 14px;color: red;">（如有多个作者，请用逗号隔开。）</p>
        </el-form-item>
        <el-form-item label="摘要"
                      :label-width="formLabelWidth"
                      prop="paperzhaiyao">
          <el-input type="textarea"
                    v-model="applyForm.paperzhaiyao"
                    style="width:410px;"
                    :rows="5"></el-input>
        </el-form-item>
      </div>

      <div v-else-if="applyForm.styles == '0' || applyForm.styles == '9'">
        <el-form-item label="标题"
                      :label-width="formLabelWidth"
                      prop="paperTitle">
          <el-input v-model="applyForm.paperTitle"
                    autocomplete="off"
                    style="width:350px;"></el-input>
        </el-form-item>
        <el-form-item label="作者"
                      :label-width="formLabelWidth"
                      prop="otherEditor">
          <el-input v-model="applyForm.otherEditor"
                    autocomplete="off"
                    style="width:350px;"></el-input>
          <p style="font-size: 14px;color: red;">（如有多个作者，请用逗号隔开。）</p>
        </el-form-item>
        <el-form-item label="摘要"
                      :label-width="formLabelWidth"
                      prop="paperzhaiyao">
          <el-input type="textarea"
                    v-model="applyForm.paperzhaiyao"
                    style="width:410px;"
                    :rows="5"></el-input>
        </el-form-item>
      </div>

      <div v-else>
        <el-form-item label="标题"
                      :label-width="formLabelWidth"
                      prop="paperTitle">
          <el-input v-model="applyForm.paperTitle"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入标题"></el-input>
        </el-form-item>
        <div v-for="(item,index) in authorUnitArr">
          <el-form-item label="作者"
                        :label-width="formLabelWidth"
                        prop="ultimateAuthor">
            <el-input v-model="item.author"
                      autocomplete="off"
                      style="width:350px;"
                      placeholder="请输入作者姓名"
                      @blur="authorBlur(item.author)"></el-input>
          </el-form-item>
          <el-form-item label="单位"
                        :label-width="formLabelWidth"
                        prop="otherCompany">
            <el-input v-model="item.unit"
                      autocomplete="off"
                      style="width:350px;"
                      placeholder="请输入单位"
                      @blur="unitBlur(item.unit)"></el-input>
            <el-button @click="addAuthorUnit">+</el-button>
            <p style="font-size: 14px;color: red;">（如有多个作者和单位，请点击“+”填写。）</p>
          </el-form-item>
        </div>
        <el-form-item label="研究方向"
                      :label-width="formLabelWidth"
                      prop="researchDir">
          <div v-for="(sub,index) in firstSubjectList"
               :key="index"
               style="margin-top:10px;">
            <el-select v-model="sub.levelOne"
                       style="width:150px;"
                       @focus="zindex=index"
                       @change="changeScholr"
                       placeholder="请选择">
              <el-option v-for="(item,index) in oneSubjectList"
                         :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
            <el-select v-model="sub.levelTwo"
                       placeholder=""
                       @focus="zindex=index"
                       @change="changeScholr1"
                       style="width:150px;"
                       placeholder="请选择">
              <el-option v-for="(item,index) in sub.twoSubjectList"
                         :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
            <el-select v-if="sub.threeSubjectList"
                       v-model="sub.levelThree"
                       style="width:150px;">
              <el-option v-for="(item,index) in sub.threeSubjectList"
                         :label="item.subjectName"
                         :value="item.id"></el-option placeholder="请选择">
            </el-select>
            <el-input v-if="!sub.threeSubjectList"
                      v-model="sub.custom"
                      autocomplete="off"
                      style="width:150px;"
                      placeholder="请选择"></el-input>
            <el-button v-if="firstSubjectList.length>1"
                       @click="delfirstSubject(index)">删除</el-button>
          </div>
          <el-button @click="addSchor()"
                     style="margin-top:10px;">+</el-button>
          <p style="font-size: 14px;color:red;">（如果您有更多研究方向，请点击“+”选择填写）</p>
        </el-form-item>
        <el-form-item label="关键词"
                      :label-width="formLabelWidth"
                      prop="paperKeyWord">
          <el-input v-model="applyForm.paperKeyWord"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord1"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord2"
                    autocomplete="off"
                    style="width:100px;"></el-input>
          <el-input v-model="applyForm.paperKeyWord3"
                    autocomplete="off"
                    style="width:100px;"></el-input>
        </el-form-item>
        <el-form-item label="中图分类号"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.otherBookStyle"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入中图分类号"></el-input>
        </el-form-item>
        <el-form-item label="学术刊发"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.otherBookPublication"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入学术刊发"></el-input>
        </el-form-item>
        <el-form-item label="收录证书编号"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.otherCertificateNum"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入收录证书编号"></el-input>
        </el-form-item>
        <el-form-item label="版权证书编号"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.otherCopyright"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入版权证书编号"></el-input>
        </el-form-item>
        <el-form-item label="基金项目"
                      :label-width="formLabelWidth">
          <el-input v-model="applyForm.paperProject"
                    autocomplete="off"
                    style="width:350px;"
                    placeholder="请输入基金项目"></el-input>
        </el-form-item>
        <el-form-item label="摘要"
                      :label-width="formLabelWidth"
                      prop="paperzhaiyao">
          <el-input type="textarea"
                    v-model="applyForm.paperzhaiyao"
                    style="width:410px;"
                    :rows="5"></el-input>
        </el-form-item>
      </div>

    </el-form>
    <div slot="footer"
         class="dialog-footer"
         style="text-align: center;">
      <el-button type="primary"
                 @click="indexApplyNextVisible('ruleApply')">下一步</el-button>
    </div>

    <el-dialog :visible.sync="centerDialogVisibles"
               v-loading="loading"
               @close='callback'
               width="700px"
               :append-to-body='true'
               center>
      <div v-if="this.applyForm.styles==2">
        <span style="float: left;height: 140px;line-height: 140px;font-weight: bolder;font-size: 16px;color: red;">*</span>
        <span style="float: left;height: 140px;line-height: 140px;font-weight: bolder;font-size: 16px;">上传封面</span>
        <el-upload class="avatar-uploader"
                   :action="uploadUrl"
                   :show-file-list="false"
                   :on-success="handleAvatarSuccess"
                   :before-upload="beforeAvatarUpload"
                   style="float: left;margin-left: 35px;">
          <img v-if="imageUrl"
               :src="imageUrl"
               class="avatar"
               style="width:130px;height:130px;">
          <i v-else
             class="el-icon-plus avatar-uploader-icon"
             style="line-height:140px;"></i>
        </el-upload>
      </div>
      <div style="clear:both;"></div>
      <div style="margin-top:20px;">
        <div class="zhezhao"
             style="width: 90%;height: 65px;position: absolute;opacity: 0;display: none;"></div>
        <el-upload class="upload-demo"
                   :action="uploadUrl"
                   :on-preview="handlePreview"
                   :on-exceed="handleExceed"
                   :file-list="fileList"
                   :show-file-list="false"
                   accept=".pdf,.mp4,.avi,.doc,.docx"
                   :on-success="handleSuc"
                   :on-progress="progress"
                   :on-error="handleError"
                   :before-upload="beforeAvatarUpload1">
          <div class=""
               style="text-align: left;">
            <span style="color:red;float:left;height:35px;line-height: 35px;">*</span>
            <span style="font-size:16px;font-weight: bolder;float:left;height:35px;line-height: 35px;">上传成果</span>
            <el-button size="small"
                       type="primary"
                       style="margin-left:20px;">点击上传</el-button>
          </div>
          <div style="clear:both;"></div>
          <p style="color:red;font-size:14px;margin-top:10px;text-align: left;">支持文件格式：doc、docx、pdf，且文件不超过15M;mp4、avi,且文件不超过100M</p>
          <p style="color:red;font-size:14px;margin-top:10px;text-align: left;">支持单个文件上传，最大上传次数不超过3次</p>
        </el-upload>
        <ul class="el-upload-list el-upload-list--text">
          <li tabindex="0"
              class="el-upload-list__item is-success"
              v-if="pathArr.length"
              v-for="(item,index) in pathArr">
            <a class="el-upload-list__item-name">
              <i class="el-icon-document"></i>
              {{item.name}}
            </a>
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-circle-check"></i>
            </label>
            <i class="el-icon-close"
               @click="handleRemove(item.id,index)"></i>
            <i class="el-icon-close-tip">按 delete 键可删除</i>
          </li>
          <li tabindex="0"
              class="el-upload-list__item is-success"
              v-if="imgFlag==true">
            <a class="el-upload-list__item-name">
              <i class="el-icon-document"></i>
              {{fileName}}
            </a>
            <el-progress :percentage="percent"
                         style="margin-top: 4px;"></el-progress>
          </li>
        </ul>
      </div>
      <h3 style="margin-top:15px;height:40px;line-height:40px;border-bottom:dashed #000 1px;">评议需求</h3>
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="137px"
               class="demo-ruleForm"
               style="margin-top:20px;">
        <el-form-item label="评议专家研究方向"
                      prop="levelId">
          <div v-for="(sub,index) in subjectList"
               :key="index"
               style="margin-top:10px;">
            <el-select v-model="sub.levelOne"
                       @change="changeOne"
                       @focus="zindex=index">
              <el-option v-for="(item,index) in oneSubjectList"
                         :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
            <el-select v-model="sub.levelTwo">
              <el-option v-for="(item,index) in sub.twoSubjectList"
                         :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
            <el-button v-if="subjectList.length>1"
                       @click="delSubject(index)">删除</el-button>
          </div>
          <el-button style="margin-top:10px;"
                     @click="addSubject()">+</el-button>
        </el-form-item>

        <el-form-item label="评议专家职称级别"
                      prop="dictTitle">
          <el-select v-model="ruleForm.dictTitle"
                     @change="changeDict">
            <el-option label="副高级"
                       value="副高级"></el-option>
            <el-option label="正高级"
                       value="正高级"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评议报告数量"
                      prop="reportNum">
          <el-input v-model="ruleForm.reportNum"
                    style="width:100px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   v-if="imgFlag==false"
                   @click="uploadClick('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
var vm;
export default {
  data: function () {
    //评议报告数量
    const reportCountCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error('评议报告数量不能为空！'));
      } else if (!(/^(:?[1-9]|[1-4]\d|50)$/.test(this.ruleForm.reportNum))) {
        callback(new Error('评议报告数量格式不正确（1-50 正整数）！'));
      } else {
        callback();
      }
    };
    return {
      publicUrl: publicUrl,
      commonUrl: commonUrl,
      zindex: null,
      percent: 0,
      imgFlag: false,
      fileName: '',
      isLt100M: '',
      isLt15M: '',
      pathArr: [],
      ajaxUrl: ajaxUrl,
      uploadUrl: null,
      prevId: 0,
      subject: [],
      token: '',
      loading: false,
      articleAttachmentId: null,
      authorUnitArr: [{
        author: null,
        unit: null,
      }],
      userId: null,
      type: null,                         //学术成果类型
      centerDialogVisibles: false,
      imageUrl: '',
      fileList: [],
      thesis: [],
      oneSubjectList: [],
      twoSubjectList: [],
      subjectList: [
        {
          custom: "",
          levelOne: null,
          levelTwo: null,
          levelThree: null,
          isCustom: false,
          listTwo: [],
          listThree: [],
        }
      ],
      firstSubjectList: [
        {
          custom: "",
          levelOne: null,
          levelTwo: null,
          levelThree: null,
          levelOneName: null,
          levelTwoName: null,
          levelThreeName: null,
          isCustom: false,
          listTwo: [],
          listThree: [],
        }
      ],
      dictName: '',
      ruleForm: {
        levelId: '',
        dictTitle: '',
        reportNum: '',
      },
      rules: {
        levelId: [
          { required: true, message: '评议专家研究方向不能为空', trigger: 'change' }
        ],
        dictTitle: [
          { required: true, message: '评议专家职称级别不能为空', trigger: 'change' }
        ],
        reportNum: [
          { required: true, validator: reportCountCheck, trigger: 'blur' }
        ],
      },
      applyForm: {
        paperTitle: '',
        paperKeyWord: '',
        paperKeyWord1: '',
        paperKeyWord2: '',
        paperKeyWord3: '',
        paperKeyWord4: '',
        styles: 1,
        bookstyle: '',  //著作中的图书分类
        bookNumber: '',  //著作中的图书字数
        publishTime: '',  // 著作的出版时间
        paperProject: '', //论文基金项目
        paperOther: '',  //	论文模板其他作者
        paperTelAuthor: '', //论文通讯作者
        firstAuthor: '',  //论文第一作者
        paperzhaiyao: '', //论文摘要
        otherEditor: '',  //著作的其他主编
        patentTitle: '',   //专利标题
        patentAuthor: '',  //专利作者
        ultimateAuthor: '',  //四个字模板的作者
        otherCompany: '',
        researchDir: '',   //四个字模板的研究方向
        researchDir2: '',
        researchDir3: '',
        otherBookStyle: '',  //四个字模板的中图分类号
      },
      applyOptions: [{
        value: 1,
        label: '论文'
      }, {
        value: 2,
        label: '著作'
      }, {
        value: 9,
        label: '专利'
      }, {
        value: 4,
        label: '研究报告'
      }, {
        value: 3,
        label: '工作总结'
      }, {
        value: 5,
        label: '病例病案'
      }, {
        value: 10,
        label: '教案教程'
      }, {
        value: 6,
        label: '工程方案'
      }, {
        value: 7,
        label: '设计方案'
      }, {
        value: 8,
        label: '科技报告'
      }, {
        value: 0,
        label: '其他'
      }],
      applyRules: {
        paperTitle: [
          { required: true, message: '论文标题不能为空', trigger: 'blur' },
        ],
        styles: [
          { required: true, message: '认证类型不能为空', trigger: 'blur' },
        ],
        paperKeyWord: [
          { required: true, message: '关键词不能为空', trigger: 'blur' },
        ],
        firstAuthor: [
          { required: true, message: '第一作者不能为空', trigger: 'blur' },
        ],
        otherEditor: [
          { required: true, message: '作者不能为空', trigger: 'blur' },
        ],
        paperzhaiyao: [
          { required: true, message: '摘要不能为空', trigger: 'blur' },
        ],
        bookstyle: [
          { required: true, message: '图书类型不能为空', trigger: 'blur' },
        ],
        bookNumber: [
          { required: true, message: '图书字数不能为空', trigger: 'blur' },
        ],
        publishTime: [
          { required: true, message: '出版时间不能为空', trigger: 'blur' },
        ],
        patentAuthor: [
          { required: true, message: '作者不能为空', trigger: 'blur' },
        ],
        ultimateAuthor: [
          { required: true, message: '作者不能为空', trigger: 'blur' },
        ],
        otherCompany: [
          { required: true, message: '单位不能为空', trigger: 'blur' },
        ],
        researchDir: [
          { required: true, message: '研究方向不能为空', trigger: 'blur' },
        ],
      },
      formLabelWidth: '100px',
    }
  },
  methods: {
    callback () {
      this.pathArr = [];
      this.thesis = [];
      this.fileList = [];
      $('.zhezhao').hide();
      this.imgFlag = false;
    },
    changType (val) {
      this.type = val;
      this.styles = val;
      this.applyForm.paperTitle = '';
      this.applyForm.firstAuthor = '';
      this.applyForm.paperOther = '';
      this.applyForm.paperzhaiyao = '';
      this.applyForm.otherEditor = '';
    },
    //失去焦点
    authorBlur (val) {
      this.applyForm.ultimateAuthor = val;
    },
    //失去焦点
    unitBlur (val) {
      this.applyForm.otherCompany = val;
    },
    changeScholr (val) {
      this.prevId = val;
      this.applyForm.researchDir = val;
      if (val) {
        vm.firstSubjectList[vm.zindex].levelTwo = '';
        vm.firstSubjectList[vm.zindex].twoSubjectList = this.subject[val];
      }

    },
    //更新认证信息（回显）
    changeScholr1 (val) {
      if (val) {
        vm.firstSubjectList[vm.zindex].levelThree = '';
        vm.firstSubjectList[vm.zindex].threeSubjectList = this.subject[val];
      }
    },
    //表单验证
    indexApplyNextVisible (ruleApply) {
      this.$refs[ruleApply].validate((valid) => {
        if (valid) {
          $.ajax({
            type: "get",
            url: ajaxUrl + "/txpy-web/user/getUserInfo",
            cache: false,
            crossDomain: true,
            dataType: 'json',
            headers: { "Authorization": this.token },
            success: (res) => {
              if (res.resultCode == 200) {
                this.userId = res.content.result.id;
              }
            }
          });
          this.centerDialogVisibles = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      this.getSubjectList();
    },
    //上传功能
    handleAvatarSuccess (res, file) {
      var res = res.content;
      for (var p in res) {
        vm.articleAttachmentId = res[p].id;
      }
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    //上传前
    beforeAvatarUpload1 (file) {
      const type = file.type.split('/')[1];
      this.isLt15M = file.size / 1024 / 1024 < 15;
      this.isLt100M = file.size / 1024 / 1024 < 100;
      if (this.pathArr.length > 2) {
        $('.zhezhao').show();
        this.$message.warning(`当前选择文件已达到最大限度!`)
        return false;
      } else {
        $('.zhezhao').hide();
        if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
          if (!this.isLt15M) {
            this.$message.warning('上传文件大小不能超过 15MB!');
          }
        }
        if (type == 'mp4' || type == 'avi') {
          if (!this.isLt100M) {
            this.$message.warning('上传文件大小不能超过 100MB!');
          }
        }
        return this.isLt15M || this.isLt100M;
      }
    },

    handlePreview (file) {
      console.log(file);
    },
    //上传成功
    handleSuc (res) {
      this.imgFlag = false;
      this.percent = 0;
      var res = res.content;
      if (this.type == null) {
        this.type = 1
      }
      for (var p in res) {
        this.thesis.push(res[p].id)
      }
      if (this.pathArr.length < 3) {
        this.pathArr.push({
          id: res.fdfs.id,
          name: res.fdfs.name,
        })
      }
    },
    //上传成果失败
    handleError (file, fileList) {
      this.imgFlag = false;
      let type = fileList.raw.type.split('/')[1];
      let size = fileList.size / 1024 / 1024;
      if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        if (size < 15) {
          this.$message({
            message: '上传成果失败！',
            type: 'warning'
          });
        }
      }
      if (type == 'mp4' || type == 'avi') {
        if (size < 100) {
          this.$message({
            message: '上传成果失败！',
            type: 'warning'
          });
        }
      }

    },

    handleRemove (id, index) {
      this.pathArr.splice(index, 1);
      this.thesis.splice(this.thesis.indexOf(id), 1);
      $('.zhezhao').hide();
    },

    //上传中
    progress (event, file, fileList) {
      this.fileName = file.name;
      let type = file.raw.type.split('/')[1];
      let size = file.size / 1024 / 1024;
      if (type == 'msword' || type == 'pdf' || type == 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
        if (size < 15) {
          this.imgFlag = true;
          this.percent = Math.floor(event.percent);
        }
      }
      if (type == 'mp4' || type == 'avi') {
        if (size < 100) {
          this.imgFlag = true;
          this.percent = Math.floor(event.percent);
        }
      }
    },

    handlePreview (file) {
      console.log(file);
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //上传结束
    //获取学科
    getSubjectList () {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/subject/list",
        cache: false,
        crossDomain: true,
        dataType: 'json',
        contentType: 'application/json;charset=UTF-8',
        headers: { "Authorization": this.token },
        data: { prevId: this.prevId },
        success: (res) => {
          if (res.resultCode == '0') {
            if (this.prevId == 0) {
              this.oneSubjectList = res.content.list;
            } else {
              this.twoSubjectList = res.content.list;
            }

          }
        }
      });
    },
    changeOne (val) {
      this.prevId = val;
      this.ruleForm.levelId = val;
      this.getSubjectList();
      this.subjectList.levelTwo = ''; vm.subjectList[vm.zindex].twoSubjectList = this.subject[val];
    },
    selectBlur1 (val) {
      console.log(val)
    },
    addSubject () {
      var data = {
        custom: "",
        levelOne: null,
        levelTwo: null,
        levelThree: null,
        isCustom: false,
        listTwo: [],
        listThree: [],
      };
      this.subjectList.push(data);
    },
    addSchor () {
      var data = {
        custom: "",
        levelOne: null,
        levelTwo: null,
        levelThree: null,
        levelOneName: null,
        levelTwoName: null,
        levelThreeName: null,
        isCustom: false,
        listTwo: [],
        listThree: [],
      };
      this.firstSubjectList.push(data);
    },
    delSubject (index) {
      this.subjectList.splice(index, 1);
    },
    delfirstSubject (index) {
      this.firstSubjectList.splice(index, 1);
    },
    addAuthorUnit () {
      var data = {
        author: null,
        unit: null,
      };
      this.authorUnitArr.push(data);
    },
    //获取学科树状图
    getsubject () {
      $.ajax({
        type: "get",
        url: ajaxUrl + "/txpy-web/subject/treeList",
        cache: false,
        crossDomain: true,
        headers: { "Authorization": this.token },
        data: {
          prevId: this.prevId
        },
        async: true,
        dataType: 'json',
        success: (res) => {
          if (res.resultCode == "0") {
            this.subject = res.content.list;
          }
        }
      })
    },
    changeDict (val) {
      this.dictName = val;
      this.ruleForm.dictTitle = val;
    },
    //日期改变时
    changeDate (val) {
      this.applyForm.publishTime = val;
    },

    //表单提交
    uploadClick (ruleForm) {
      //学科
      for (var p in this.subject) {
        this.subject[p].forEach(item => {
          this.firstSubjectList.forEach(sub => {
            if (sub.levelOne == item.id) {
              sub.levelOneName = item.subjectName;
            }
            if (sub.levelTwo == item.id) {
              sub.levelTwoName = item.subjectName;
            }
            if (sub.levelThree != '') {
              if (sub.levelThree == item.id) {
                sub.levelThreeName = item.subjectName;
              }
            }
          })
        })
      }
      var subjectIdsArr = [];
      var subjectIds = '';
      this.subjectList.forEach(item => {
        subjectIdsArr.push(item.levelTwo)
      })
      subjectIds = subjectIdsArr.join(',');
      //获取类型
      if (this.type == null) {
        this.type = 1;
      }
      //获取关键词
      var keyWordArr = [];
      var keyWordStr = '';
      keyWordArr.push(this.applyForm.paperKeyWord);
      if (this.applyForm.paperKeyWord1 != '') {
        keyWordArr.push(this.applyForm.paperKeyWord1);
        if (this.applyForm.paperKeyWord2 != '') {
          keyWordArr.push(this.applyForm.paperKeyWord2);
          if (this.applyForm.paperKeyWord3 != '') {
            keyWordArr.push(this.applyForm.paperKeyWord3);
          }
        }
      }
      keyWordStr = keyWordArr.join(',');
      var fastApplyAcademicInsertRequest = {
        "academic": {
          //					    "id": 0,
          "articleAttachmentId": this.articleAttachmentId,
          "userId": this.userId,
          "title": this.applyForm.paperTitle,
          "type": this.type,
          "typeStr": this.type,
          "authorFirst": this.applyForm.firstAuthor,
          "authorOthers": this.applyForm.paperOther,
          "uploder": "string",
          "authorComm": this.applyForm.paperTelAuthor,
          "artSummary": this.applyForm.paperzhaiyao,
          "keywords": keyWordStr,
          "cnPicNo": this.applyForm.otherBookStyle,
          "fundName": this.applyForm.paperProject,
          "sciencePublish": this.applyForm.otherBookPublication,
          "status": 0,
          "statusStr": "string",
          "sort": 0,
          "sendNo": 0,
          //					    "thesis":this.thesis,
          "flag": 0,
          "appraisalTotal": 0,
          "tempFileId": this.thesis,
          "academicSubjects": this.firstSubjectList,
          "authorUnit": this.applyForm.otherEditor, "certificateNo": this.applyForm.otherCertificateNum,
          "copyrightNo": this.applyForm.otherCopyright,
          "bookClassify": this.applyForm.bookstyle,
          "bookCount": this.applyForm.bookNumber,
          "bookPubTime": this.applyForm.publishTime?this.applyForm.publishTime+' 00:00:00':this.applyForm.publishTime,
        },
        "appraiseInfo": {
          //					    "id": 0,
          "subjectName": "",
          "userId": this.userId,
          "academicId": 0,
          "gradeName": this.dictName,
          "reportCount": this.ruleForm.reportNum,
          "sortNum": 0,
          "status": 0,
          "subjectIds": subjectIds,
          "userName": "",
        }
      }
      //		console.log(this.thesis.length)
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.thesis.length != 0) {
            this.loading = true;
            $.ajax({
              type: "post",
              url: ajaxUrl + "/txpy-web/academic/fastApplyAcademicInsert",
              cache: false,
              crossDomain: true,
              dataType: 'json',
              contentType: 'application/json;charset=UTF-8',
              data: JSON.stringify(fastApplyAcademicInsertRequest),
              headers: { "Authorization": this.token },
              success: (res) => {
                this.loading = false;
                //							console.log(res)
                if (res.resultCode == '0') {
                  this.$message({
                    message: '提交成功！',
                    type: 'success'
                  });
                  this.centerDialogVisibles = false;
                  setTimeout(() => {
                    window.location.href = commonUrl + `?token=${this.token}`;
                  }, 1000)
                }
              }
            });
          } else {
            this.$message({
              message: '请上传学术成果',
              type: 'warning'
            });
          }
        }
      })
    },


  },
  mounted () {
    vm = this;
    this.getSubjectList();
    this.getsubject();
    this.uploadUrl = ajaxUrl + `/txpy-web/test/common/upload?token=${this.token}`;
  },
  created () {
    this.token = JSON.parse(localStorage.getItem('token'));
  }
}
</script>
