<template>
    <div class="mainContentOther">
	<el-form :model="ruleFormOther" :rules="rulesOther" ref="ruleFormOther" label-width="100px" class="demo-ruleForm">
		<el-form-item label="作者" prop="author">
			<el-input v-model="ruleFormOther.author"></el-input>
		</el-form-item>
		<div class="authorAdd">
			<p><span>+</span>（如有多个作者，请点击“+”填写。）</p>
		</div>
		<el-form-item label="摘要" prop="abstract">
			<el-input type="textarea" v-model="ruleFormOther.abstract"></el-input>
		</el-form-item>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="upload">上传</span>
		</div>
		<p class="remarkText">支持WORD、PDF、PPT，且文件不超过15M；JPG、PNG，且文件不超过1M</p>
	</el-form>
	<div class="btnOther">
		<span class="btnOtherCancel" @click="btnCancel('ruleFormOther')">取消</span>
		<span class="btnOtherUpload">上传</span>
	</div>
</div>
</template>
<script>
export default {
    data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			/*其他*/
			ruleFormOther:{
				author:'',
				abstract:'',/*摘要*/
			},
			rulesOther:{
				author:{ required: true, message: '请输入作者姓名', trigger: 'blur' },
				abstract:{ required: true, message: '请输入摘要', trigger: 'blur' },
			},
		}
	},
	methods:{
		btnCancel:function(formName){
			this.$refs[formName].resetFields();
		},
	}
}
</script>