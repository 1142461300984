<template>
    <div class="mainContentWork" >
	<el-form :model="ruleFormWork" :rules="rulesWork" ref="ruleFormWork" label-width="100px" class="demo-ruleForm">
		<el-form-item label="图书名称" prop="bookName">
			<el-input v-model="ruleFormWork.bookName"></el-input>
		</el-form-item>
		<el-form-item label="图书分类" prop="type">
			<el-input v-model="ruleFormWork.type"></el-input>
		</el-form-item>
		<el-form-item label="图书字数" prop="num">
			<el-input v-model="ruleFormWork.num"></el-input>
		</el-form-item>
		<el-form-item label="出版时间">
			<el-date-picker v-model="ruleFormWork.timer" type="date" placeholder="选择日期">
			</el-date-picker>
		</el-form-item>
		<el-form-item label="第一主编" prop="oneauthor">
			<el-input v-model="ruleFormWork.oneauthor"></el-input>
		</el-form-item>
		<el-form-item label="第二主编">
			<el-input v-model="ruleFormWork.twoauthor"></el-input>
		</el-form-item>
		<el-form-item label="其他主编">
			<el-input v-model="ruleFormWork.otherauthor"></el-input>
		</el-form-item>
		<div class="authorAdd">
			<p><span>+</span>（如有多个作者，请点击“+”填写。）</p>
		</div>
		<el-form-item label="摘要">
			<el-input type="textarea" v-model="ruleFormWork.abstract"></el-input>
		</el-form-item>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="uploadimg">
											<i>+</i>
											<b>点击上传</b>
										</span>
		</div>
		<p class="remarkText">仅支持JPG、PNG、PDF,且不超过1M</p>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="upload">上传</span>
		</div>
		<p class="remarkText">支持PDF，且文件不超过50M</p>
	</el-form>
	<div class="btnPaper">
		<span class="btnPaperCancel" @click="btnCancel('ruleFormWork')">取消</span>
		<span class="btnPaperUpload">上传</span>
	</div>
</div>
</template>
<script>
export default {
	data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			/*著作*/
			ruleFormWork:{
				bookName:'',/*图书名称*/	
				type:'',/*图书分类*/	
				num:'',/*图书字数*/
				oneauthor:'',/*第一主编*/
				twoauthor:'',/*第二主编*/
				otherauthor:'',/*其他主编*/
				timer:'',/*时间*/
			},
			rulesWork:{
				bookName:{ required: true, message: '请输入图书名称', trigger: 'blur' },
				type:{ required: true, message: '请输入图书分类', trigger: 'blur' },
				num:{ required: true, message: '请输入图书字数', trigger: 'blur' },
				oneauthor:{ required: true, message: '请输入第一主编', trigger: 'blur' },
			},
		}
	},
	methods:{
		btnCancel:function(formName){
			this.$refs[formName].resetFields();
		},
	}
}
</script>
<style scoped>

</style>