// 获取url参数
window.getQueryVariable = function (variable) {
	var query = decodeURI(window.location.search.substring(1));
	var vars = query.split("&");
	for(var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable) {
			return pair[1];
		}
	}
	return(false);
}

// 改成组件后，这个方法就没用了
window.reutrnRes = function (url) {
	var same = null;
	jQuery.ajax({
		type: "get",
		url: url,
		cache: false,
		async: false,
		success: function(res) {
			same = res
		}
	});
	return same
}

if(location.href.indexOf(".com")!=-1 || location.href.indexOf("1234")!=-1){
	var publicUrl = 'http://www.tonghangpingyi.com/';
	var commonUrl = 'http://www.tonghangpingyi.com/';
	var imgPath = 'http://fdfs.tonghangpingyi.com/';           //线上图片路径
	var ajaxUrl = 'http://web.tonghangpingyi.com';             //线上ajax请求前缀
	var loginUrl = 'http://web.tonghangpingyi.com';
	var regUrl = 'http://auth.cnknowledge.com';
}else{
	var publicUrl = "http://47.95.200.179/";
	var commonUrl = 'http://47.95.200.179/';
	var imgPath = 'http://fdfs.cnknowledge.com/';
	var ajaxUrl = 'http://47.93.50.49:8883';
	var loginUrl = 'http://47.93.50.49:8883';
	var regUrl = 'http://dev.cnknowledge.com:8082';
	// var publicUrl = "http://192.168.1.11:8020/";
	// var commonUrl = 'http://192.168.1.11:83/';
	// var imgPath = 'http://192.168.1.11/';
	// var ajaxUrl = 'http://192.168.1.11:8881';
	// var loginUrl = 'http://192.168.1.11:8881';
	// var regUrl = 'http://192.168.1.11:8082';
}




// 临时用这种方式注册到全局，其实应该用模块的方式，但是各个地方写到的太多了...
window.publicUrl = publicUrl
window.commonUrl = commonUrl
window.ajaxUrl = ajaxUrl
window.loginUrl = loginUrl
window.regUrl = regUrl
window.imgPath = imgPath
