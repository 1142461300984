import 'babel-polyfill'

import Vue from 'vue/dist/vue.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//  /*全局引入*/
// import vueSwiper from 'vue-awesome-swiper/dist/vue-awesome-swiper' //引入vue-awesome-swiper
// import 'swiper/css/swiper.min.css'
// Vue.use(vueSwiper)


import jQuery from 'jquery'
window.jQuery = window.$ = jQuery

import './common/jquery.cookie.js'

import './common/common.js'
import './common/common.css'

var global = {
  transferYear(times){//时间戳转时间年月日
    if(typeof(times)!="number"){

      if(times.indexOf('T')!='-1'){
        var t=times.split('T')
      }else{
        var t=times.split(' ')
      }
      return t[0]
    }else{
      var date = new Date(times);
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
      console.log(date.getYear())
      return Y+M+D;
    }
  },
}
Vue.prototype.$global = global;

import reviewIndexheader from '../components/review-indexheader.vue'
import reviewIndexfooter from '../components/review-indexfooter.vue'
import reviewHeader from '../components/review-header.vue'
import reviewFooter from '../components/review-footer.vue'
import applys from '../components/applications.vue'
import personApplyedit from '../components/person-applyedit.vue'
import personApply from '../components/person-apply.vue'
import expertForm from '../components/expert-form.vue'
import level from '../components/level.vue'
import mainReport from '../components/main-report.vue'
import mainWork from '../components/main-work.vue'
import mainPaper from '../components/main-paper.vue'
import mainPatent from '../components/main-patent.vue'
import mainOther from '../components/main-other.vue'



Vue.component('reviewIndexheader', reviewIndexheader)
Vue.component('reviewIndexfooter', reviewIndexfooter)
Vue.component('reviewHeader', reviewHeader)
Vue.component('reviewFooter', reviewFooter)
Vue.component('applys', applys)
Vue.component('personApplyedit', personApplyedit)
Vue.component('personApply', personApply)
Vue.component('expertForm', expertForm)
Vue.component('level', level)
Vue.component('mainReport', mainReport)
Vue.component('mainWork', mainWork)
Vue.component('mainPaper', mainPaper)
Vue.component('mainPatent', mainPatent)
Vue.component('mainOther', mainOther)




export default Vue
