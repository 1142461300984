<template>
    <div class="mainContentReport" >
	<el-form :model="ruleFormReport" :rules="rulesReport" ref="ruleFormReport" label-width="100px" class="demo-ruleForm">
		<el-form-item label="标题" prop="title">
			<el-input v-model="ruleFormReport.title"></el-input>
		</el-form-item>
		<el-form-item label="作者" prop="author">
			<el-input v-model="ruleFormReport.author"></el-input>
		</el-form-item>
		<div class="authorAdd">
			<p><span>+</span>（如有多个作者，请点击“+”填写。）</p>
		</div>
		<el-form-item label="单位" prop="work">
			<el-input v-model="ruleFormReport.work"></el-input>
		</el-form-item>
		<div class="workAdd">
			<p><span>+</span>（如果有其他单位的作者，请点击“+”填写。）</p>
		</div>
		<div class="researchMain">
			<el-form-item label="研究方向">
				<el-select v-model="directionvalue1">
					<el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-select v-model="directionvalue2">
					<el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-input v-model="ruleFormReport.research"></el-input>
			</el-form-item>
		</div>
		<div class="directionAdd">
			<p><span>+</span>（如果该文献涉及多个研究方向，请点击“+”选择填写。）</p>
		</div>
		<el-form-item label="中国分类号">
			<el-input v-model="ruleFormReport.num2" ></el-input>
		</el-form-item>
		<el-form-item label="学术刊发">
			<el-input v-model="ruleFormReport.num3" ></el-input>
		</el-form-item>
		<el-form-item label="收录证书编号">
			<el-input v-model="ruleFormReport.num4"></el-input>
		</el-form-item>
		<el-form-item label="版权证书编号">
			<el-input v-model="ruleFormReport.num5"></el-input>
		</el-form-item>
		<el-form-item label="基金项目">
			<el-input v-model="ruleFormReport.project" ></el-input>
		</el-form-item>
		<div class="antistop">
			<el-form-item label="关键词" prop="antistop">
				<el-input v-model="ruleFormReport.antistop"></el-input>
				<el-input v-model="ruleFormReport.antistop1"></el-input>
				<el-input v-model="ruleFormReport.antistop2"></el-input>
				<el-input v-model="ruleFormReport.antistop3"></el-input>
			</el-form-item>
		</div>
		<el-form-item label="摘要" prop="abstract">
			<el-input type="textarea" v-model="ruleFormReport.abstract"></el-input>
		</el-form-item>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="upload">上传</span>
		</div>
		<p class="remarkText">支持WORD、PDF、PPT，且文件不超过15M；JPG、PNG，且文件不超过1M</p>
		<table class="mainContentReportTable">
			<tr>
				<th style="width: 80px;">类型</th>
				<th>文件</th>
			</tr>
			<tr>
				<td>论文</td>
				<td>无</td>
			</tr>
		</table>
	</el-form>
	<div class="btnOther">
		<span class="btnOtherCancel" @click="btnCancel('ruleFormReport')">取消</span>
		<span class="btnOtherUpload">上传</span>
	</div>
</div>
</template>
<script>
export default {
	data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			/*研究报告*/
			ruleFormReport:{
				title:'',
				author:'',
				abstract:'',/*摘要*/
				work:'',
				research:'',/*研究方向*/
				project:'',/*基金项目*/
				antistop:'',/*关键字*/
				antistop1:'',/*关键字1*/
				antistop2:'',/*关键字2*/
				antistop3:'',/*关键字3*/
				num2:'',
				num3:'',
				num4:'',
				num5:'',
			},
			rulesReport:{
				title:{ required: true, message: '请输入标题', trigger: 'blur' },
				author:{ required: true, message: '请输入作者姓名', trigger: 'blur' },
				work:{ required: true, message: '请输入单位名称', trigger: 'blur' },
				antistop:{ required: true, message: '请输入关键词', trigger: 'blur' },
			},
			
			options1:[{
				value:'数学',
				label:'数学',
			},{
				value:'信息科学与系统科学',
				label:'信息科学与系统科学',
			},{
				value:'力学',
				label:'力学',
			},{
				value:'物理学',
				label:'物理学',
			}],
			options2:[{
				value:'数学史',
				label:'数学史',
			},{
				value:'数理逻辑与教学基础',
				label:'数理逻辑与教学基础',
			},{
				value:'数论',
				label:'数论',
			},{
				value:'代数学',
				label:'代数学',
			}],
			directionvalue1:'基础数学',
			directionvalue2:'药理学',
		}
	},
	methods:{
		btnCancel:function(formName){
			this.$refs[formName].resetFields();
		},
	}
}
</script>