<template>
    <div>
	 <FormItem label="研究方向" :label-width="120" v-if="isload">
           <div class="subject" v-for="(item,mindex) in SsoSubject" :key="mindex" @click="selectIndex(mindex)">
                   <el-select v-model="item.levelOne" placeholder="请选择一级" class="selectCity" @change="changeOne">
                       <el-option v-for="ite in onelist" :value="ite.id" :key="ite.id">{{ ite.subjectName }}</el-option>
                   </el-select>
                   <el-select v-model="item.levelTwo" placeholder="请选择二级" class="selectCity" @change="changeTwo">
                      <el-option v-for="ite in item.listTwo" :value="ite.id" :key="ite.id">{{ ite.subjectName }}</el-option>
                  </el-select>
                  <el-select v-if="!item.isCustom&&!item.custom" v-model="item.levelThree" placeholder="请选三级" class="selectCity" @change="changeThree">
                     <el-option v-for="ite in item.listThree" :value="ite.id" :key="ite.id">{{ ite.subjectName }}</el-option>
                  </el-select>
                <el-input v-if="item.isCustom||item.custom" class="w2" type="text" v-model="item.custom" placeholder="自定义方向"/>
                <el-button v-if="SsoSubject.length!=1" type="error" icon="md-remove" @click="removeSubject(mindex)">移除</el-button>
           </div>
           <el-button class="add" type="info" icon="md-add" @click="addSubject()">添加</el-button>
       </FormItem>
</div>

</template>

<script>
export default {
	data:function(){
		return {
			key:0,
          	onelist:[],
          	SsoSubject:[],
          	isload:false,

		}
	},
	mounted(){
      vm=this;
      vm.ssoSubject(0,0,0);
      vm.SsoSubject=vm.subject;
	},
    watch:{
      commitSatus(newName,oldName){
        vm.$emit('fun', vm.SsoSubject);
      },
    },
	methods:{
      selectIndex(mindex){
        vm.key=mindex;
      },
      addSubject(){
		let data={
			custom:"",
			levelOne:null,
			levelTwo:null,
			levelThree:null,
			isCustom:false,
			listTwo:[],
			listThree:[],
		}
          if(vm.SsoSubject.length==10){
            this.$Message.error('最多添加10条数据！');
            return;
          }
  				for(var i=0;i<vm.SsoSubject.length;i++){
  					if(!vm.SsoSubject[i].levelTwo){
  						this.$Message.error('请先完成上一条研究方向！');
  						return;
  					}
  				}
  				vm.SsoSubject.push(data);
  		},
  		removeSubject(index){
  			vm.SsoSubject.splice(index,1);
  		},
      	//省
  		ssoSubject(val,index,key){
  			$.ajax({
				type: "get",
				url: ajaxUrl + "/txpy-web/subject/list",
				cache: false,
				crossDomain:true,
				headers:{"Authorization":this.token},
				data:{
					prevId:this.prevId
				},
				success:(res) => {
	  				if(result.messageType == "Success") {
		            	let res=result.content.list||[];
			            if(res){
			                if(index==0){
			                  vm.onelist=res;
			                  vm.isload=true;
			                }else if(index==1){
			                  this.$set(vm.SsoSubject[key],'listTwo', res)
			                }else{
			                  this.$set(vm.SsoSubject[key],'listThree', res)
			                }
			            }
			            if(index==2&&!res.length){
			              vm.SsoSubject[key].isCustom=true
			            }
		  			}else{
		            	this.$Message.error(res.resultDesc);
	          		}
		  		}
  			});
  		},
      load(){
        setTimeout(() => {
          vm.isload=true
        },100);
      },
      //省
      changeOne(val,index){
        index=index?index:vm.key;
        vm.ssoSubject(val,1,index);
      },
      changeTwo(val,index){
        index=index?index:vm.key;
        vm.ssoSubject(val,2,index);
      },
      changeThree(val,index){
        index=index?index:vm.key;
      },
  	},
  	created(){
  		this.token = JSON.parse(localStorage.getItem('token'));
  	}
}
</script>

<style scoped>

</style>