<template>
    <div class="mainContentPatent" >
	<el-form :model="ruleFormPatent" :rules="rulesPatent" ref="ruleFormPatent" label-width="100px" class="demo-ruleForm">
		<el-form-item label="作者" prop="authorPatent">
			<el-input v-model="ruleFormPatent.authorPatent"></el-input>
		</el-form-item>
		<div class="authorAdd">
			<p><span>+</span>（如有多个作者，请点击“+”填写。）</p>
		</div>
		<el-form-item label="摘要" prop="abstractPatent">
			<el-input type="textarea" v-model="ruleFormPatent.abstractPatent"></el-input>
		</el-form-item>
		<div class="load">
			<p><i>*</i><span>上传论文</span></p>
			<span class="upload">上传</span>
		</div>
		<p class="remarkText">支持WORD、PDF、PPT，且文件不超过15M；JPG、PNG，且文件不超过1M</p>
	</el-form>
	<div class="btnPatent">
		<span class="btnPatentCancel" @click="btnCancel('ruleFormPatent')">取消</span>
		<span class="btnPatentUpload">上传</span>
	</div>
</div>
</template>

<script>
export default {
    data:function() {
		return {
			publicUrl:publicUrl,
			commonUrl:commonUrl,
			/*专利*/
			ruleFormPatent:{
				authorPatent:'',
				abstractPatent:'',/*摘要*/
			},
			rulesPatent:{
				authorPatent:{ required: true, message: '请输入作者姓名', trigger: 'blur' },
				abstractPatent:{ required: true, message: '请输入摘要', trigger: 'blur' },
			},
		}
	},
	methods:{
		btnCancel:function(formName){
			this.$refs[formName].resetFields();
		},
	}
}
</script>
